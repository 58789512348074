import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import { LOGIN_ROUTE } from "../../utils/consts";

import './firstPage.sass';


const FirstPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container className="first">
            <Helmet>
                <title>Вступление</title>
                <meta name="description" content="Вступление" />
            </Helmet>

            <h1 className="first__title">ЖИЗНЬ БЕЗ ОНАНИЗМА И ПОРНОГРАФИИ</h1>
            <h3 className="first__title_small">Срываешься на порно? Не можешь бросить онанизм? <br/> Узнай как жить без порно и онанизма стабильно.</h3>
            <p className="first__text">
                Наверняка тебе знакомо то ощущение, когда сорвавшись в очередной раз на порнушку, впал в запой… а потом упадок сил, опустошенность, несделанные дела и чувства стыда и неуверенности в себе… и вот ты обещаешь себе, что это был последний раз, теперь ты всё осознал, и будешь держаться до конца… Но такого решения хватает в среднем на неделю или две, а там из-за какого-то пустяка (триггер, эротическая реклама, или сексуальные фантазии) ты опять срываешься… А дальше всё по кругу: упадок сил, несделанные дела, обещание, короткий период воздержания и снова срыв… Не надоело??
            </p>
            <h3 className="first__title_small">Предлагаю тебе методику, которая научит тебя как жить без онанизма и порно стабильно, без срывов.</h3>
            <h4 className="first__title_small">Жизнь без онанизма и порно принесёт тебе:</h4>
            <ul className="first__list">
                <li>Повышение самооценки, уважение себя</li>
                <li>Избавление от стыда и чувства вины</li>
                <li>Повышение привлекательности в глазах женского пола</li>
                <li>Энергичное, бодрое состояние</li>
                <li>Восстановится чувствительность дофаминовых рецепторов, и как следствие, способность радоваться мелочам</li>
                <li>Способность заниматься делами</li>
                <li>Повышение уровня тестостерона</li>
            </ul>
            <h3 className="first__title_small">Вот, как это работает</h3>
            <p className="first__text">
                Возможно, всё, что попадалось тебе до этого, было основано на отрицательной постановке цели (НЕ дрочить) и таких же отрицательных инструкциях — НЕ смотри, НЕ трогай, НЕ думай и т.д… И если ты сейчас читаешь этот текст в поисках новой помощи бросить дрочить, значит эффективность тех методик невелика.
                Моя методика основана на том, что именно надо делать, какие действия совершать, чтобы победить зависимость. 
            </p>
            <h3 className="first__title_small">Предлагаю тебе воспользоваться методикой и раз и навсегда попрощаться с порнозависимостью.</h3>
            <p className="first__text">Для этого тебе нужно зарегистрироваться на сайте и следовать инструкциям.</p>
          
            <Button 
                className="first__btn"
                variant={"outline-light"} 
                onClick={() => navigate(LOGIN_ROUTE)}
                >Авторизация
            </Button>
        </Container>
    );
};

export default FirstPage;