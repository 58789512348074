import React from 'react';
import {Container} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import BackBlock from './BackBlock';

import './methodBlock.sass';


const MethodBlock4: React.FC = () => {
    return (
        <Container className="method">
            <Helmet>
                <title>Методика. Часть 4</title>
                <meta name="description" content="Часть 5" />
            </Helmet>

            <h3 className="method__title">Часть 4</h3>
            <p className="method__paragraph">Наша цель - жить без онанизма и порнографии легко, стабильно, не думая об ОП, а если такие мысли бы возникали, то они не задерживаясь, улетали бы прочь, не побуждая к срыву.</p>
            <p className="method__paragraph">Цель классная. Но! Такое желанное состояние не учит как справляться с зависимостью и не защищает в момент вожделения от срыва, а значит не может быть устойчиво без твоей способности дать отпор зависимости, если такая ситуация возникнет.</p>
            <p className="method__paragraph">Одержать победу над ОП помогает именно твоя способность противостоять зависимости в моменты вожделения, одержимости, искушения.</p>
            <p className="method__paragraph">И за это отвечает привычка “Бороться и Побеждать”.</p>
            <p className="method__paragraph">Цель - выработать такую привычку бороться и побеждать, чтобы ты мог на неё опереться.</p>
            <p className="method__paragraph">На прошлом занятии мы выяснили, что способствует ЖБОиП - делать чаще. А что способствует ОП - минимизировать, делать по возможности реже. Но! Ты НЕ должен этого бояться. Ты должен быть бдителен, но НЕ избегать подобного, если этого требует ситуация.</p>
            <p className="method__paragraph">Цель - жить НЕ в страхе перед возможным срывом, а с твёрдой убеждённостью, что ты сможешь справится в критический момент с искушением. Но специально НЕ провоцируя и НЕ искушая себя, НЕ проверяя на прочность.</p>
            <p className="method__paragraph">Самое главное - опираться НЕ на негативную  мотивацию (не дрочить), а на положительную - на свои цели, которые ты сможешь достичь, победив онанизм и порнозависимость (смотри свою Анкету). И соответственно уделять этому большую часть своего времени.</p>
            <p className="method__paragraph">Срыв... Почему происходят срывы?</p>
            <p className="method__paragraph">Сдаваться - это привычка. Привычка, выработанная годами (в моём случае более 20 лет). Были ситуации, когда мог перебороть накатившую похоть, но через день или несколько уже не выдерживал и сдавался. Были ситуации, когда при первых позывах уже без сопротивления, покорно року, сдавался... триггер, вожделение... срыв... сдался… опять...</p>
            <p className="method__paragraph">Задача - выработать новую привычку, полезную. Привычку бороться и побеждать.</p>
            <p className="method__paragraph">В следующий раз, когда появится желание посмотреть голых девиц, накатит вожделение, поглотит похоть, вместо того, чтобы сразу сдаться, скажи:<br/>
            <i>"Это было неизбежно и ожидаемо.<br/> Это вызов, и я принимаю бой!<br/> Победа в этом бою сделает меня сильнее.<br/> Сегодня Я выстою, и тем самым заложу фундамент новой привычки.<br/> Привычки бороться и побеждать."</i></p>
            <p className="method__paragraph">И после этого используй все доступные тебе средства, чтобы одолеть похоть и выйти победителем из этой битвы. Используй всё: сила воли, мотивация, переключение внимания, хитрости, упирайся, вгрызайся зубами, но выстой. Ты должен быть как монолит в своих убеждениях, как скала.</p>
            <ol className="method__list">Алгоритм такой:
                <li>Прерви деятельность, способствующую ОП</li>
                <li>Сломай ситуацию</li>
                <li>Займись деятельностью, способствующей ЖБОиП</li>
            </ol>
            <p className="method__paragraph">И в конце дня, когда будешь отходить ко сну, поблагодари себя за проявленную стойкость.</p>
            <p className="method__paragraph">Поначалу ТЫ вкладываешься в привычку, через усилия, через “не могу”, через дискомфорт, упорно, длительно, а затем ПРИВЫЧКА будет работать на тебя, и это станет нормой. Со временем будут наработаны прочные нейронные связи.</p>
            <p className="method__paragraph">Но как бы пафосно это ни звучало, на одних громких словах далеко не уедешь. Самое эффективное решение - это активные действия. Так как в момент вожделения, логическое мышление притупляется, то мы заранее составим набор конкретных действий (НКД).</p>
            <p className="method__paragraph">Для начала выделим основные категории того, на что мы можем влиять в момент борьбы с похотью:</p>
            <ul className="method__list">
                <li>Ситуация
                    <ul>
                        <li>Устрани причину, вызвавшую возбуждение</li>
                        <li>Сломай ситуацию, располагающую к ОП</li>
                    </ul>
                </li>
                <li>Деятельность
                    <ul>
                        <li>Вовлечённая / умственная</li>
                        <li>Полностью поглощающая / физическая</li>
                        <li>Важная / срочная</li>
                    </ul>
                </li>
                <li>Мысли
                    <ul>
                        <li>Разотождествление
                            <ul>
                                <li>Часть мозга поражена этой наркотической зависимостью. И для её восстановления нужно время. Это не Я хочу дрочить. Это поражённая часть мозга нуждается в дозе. Лишая её дозы, Я становлюсь сильнее, а она слабее.</li>
                            </ul>
                        </li>
                        <li>Вернись в реальность
                            <ul>
                                <li>Любая эрекция сигнализирует о том, что ты улетел в мир сексуальных фантазий. Необходимо немедленно вернуться в реальность. Осознай, что ты сейчас видишь, слышишь, ощущаешь в данный момент в данном пространстве.</li>
                            </ul>
                        </li>
                        <li>Это временно, это пройдёт
                            <ul>
                                <li>Никакая боль, никакое чувство не длится вечно. Ни одна грусть, ни одна тоска тебя не убьёт. Надо перетерпеть. Это пройдёт.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>Убеждения
                    <ul>
                        <li>Я выстою!</li>
                        <li>Вид обнаженного женского тела - мощнейший мотиватор к действию. И если ты без особых усилий, лишь пару раз кликнув мышкой, можешь его лицезреть, то у тебя не будет мотивации к действию в реальной жизни. Ты не сможешь замотивировать себя на работу для реальных достижений, пока смотришь обнаженных девиц, не прилагая для этого усилий.</li>
                        <li>Хочешь иметь соблазнительных девчонок в реальности, откажись от мыслей о них в сексуальных фантазиях.  Победа над онанизмом - мощнейший вклад в достижение секса в реальности. А онанизм провоцируется сексуальными фантазиями. И потому чем меньше ты фантазируешь о сексе, тем выше вероятность добиться его в реальности.</li>
                        <li>Вспомни о ценности семени. Один слив - это примерно 5 мл спермы, что эквивалентно 200 мл крови по содержанию в ней питательных и жизненно важных веществ, витаминов, энергетической ценности.</li>
                    </ul>
                </li>
                <li>Мотивация
                    <ul>
                        <li>Прочти свои записи в Анкете: “Зачем мне это”</li>
                        <li>Включи мотивационный  контент</li>
                    </ul>
                </li>
            </ul>
            <p className="method__paragraph">А теперь, чтобы в момент борьбы тебе не пришлось что-то придумывать, тебе предстоит составить свой набор конкретных действий.</p>
            <ul className="method__list">Покажу на своём примере:
                <li>Уйти из дома</li>
                <li>Звонок другу</li>
                <li>Тренировка</li>
                <li>Уборка</li>
                <li>Готовка (кулинария)</li>
                <li>Караоке</li>
                <li>Мотивационный контент: песня, видео, статья</li>
            </ul>
            <p className="method__paragraph">Ты составляешь свой список. Используй записи с прошлого занятия. Обрати внимание, что какие-то действия подходят в одних условиях, и не подходят в других. Например, уйти из дома в ночь, когда ты уже планировал ложиться спать, или в проливной дождь - не очень хороший вариант. Готовить еду в 12 ночи, или звонить другу - тоже как-то странно, зато эти варианты довольно хорошо подходят в дневное время. А вот уборку, тренировку, работу с органайзером или сайтом я могу проводить в любое время. При составлении своего списка учти эти особенности.</p>
            
            <h4 className="method__title">Задания:</h4>
            <ol className="method__list">
                <li>Начни развивать привычку "Бороться и Побеждать"</li>
                <li>Составь НКД и заполни на соответствующеё странице</li>
                <li>Составь список мотиваций, которые включишь в НКД</li>
            </ol>

            <BackBlock />
        </Container>
    );
};

export default MethodBlock4;