import React, {useState, useContext} from 'react';

import { createArticleCommon } from '../../http/articlesCommonAPI';
import { createArticlePrivate } from '../../http/articlesPrivateAPI';
import { createAudioCommon } from '../../http/audiosCommonAPI';
import { createAudioPrivate } from '../../http/audiosPrivateAPI';
import { createVideoCommon } from '../../http/videosCommonAPI';
import { createVideoPrivate } from '../../http/videosPrivateAPI';
import { Context } from '../..';
import CULink from '../CreateUpdate/CULink';

interface ModalLinkAddProps {
    show: boolean;
    onHide: () => void;
    pageTitle: string;
};

const ModalLinkAdd: React.FC<ModalLinkAddProps> = ({show, onHide, pageTitle}) => {
    const {users} = useContext(Context);
    const [title, setTitle] = useState<string>('');    
    const [link, setLink] = useState<string>('');
    let handler;

    switch (pageTitle) {
        case 'Аудио' :
            handler = users.role === "ADMIN" ? createAudioCommon : createAudioPrivate;
            break;
        case 'Видео' :
            handler = users.role === "ADMIN" ? createVideoCommon : createVideoPrivate;
            break;
        case 'статей' :
            handler = users.role === "ADMIN" ? createArticleCommon : createArticlePrivate;
            break;
    }

    return (
        <CULink
            id={0}
            title={title}
            link={link}
            setTitle={setTitle}
            setLink={setLink}
            // @ts-ignore
            handler={handler}
            modalTitle='Добавить ссылку'
            btnTitle='Добавить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalLinkAdd;