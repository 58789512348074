import { $authHost } from ".";

export const createAudioPrivate = async (title: string, link: string) => {
    const {data} = await $authHost.post('api/audios/private', {title, link});
    return data;
};

export const fetchAudiosPrivate = async () => {
    const {data} = await $authHost.get('api/audios/private');
    return data;
};

export const fetchOneAudioPrivate = async (id: number) => {
    const {data} = await $authHost.get('api/audios/private/' + id);
    return data;
};

export const updateAudioPrivate = async (id: number, title: string, link: string) => {
    const {data} = await $authHost.put('api/audios/private/' + id, {title, link});
    return data;
};

export const deleteAudioPrivate = async (id: number) => {
    const {data} = await $authHost.delete('api/audios/private/' + id);
    return data;
};