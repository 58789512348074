import React, {useState} from 'react';

import {createNkd} from '../../http/nkdAPI';
import {createOp} from '../../http/opAPI';
import {createZbo} from '../../http/zboAPI';
import CUItem from '../CreateUpdate/CUItem';

interface ModalItemAddProps {
    show: boolean;
    onHide: () => void;
    pageTitle: string;
};


const ModalItemAdd: React.FC<ModalItemAddProps> = ({show, onHide, pageTitle}) => {
    const [name, setName] = useState<string>('');    
    const [category, setCategory] = useState<string>('');
    let handler;

    switch (pageTitle) {
        case 'Набор конкретный действй' :
            handler = createNkd;
            break;
        case 'Способствует ОП' :
            handler = createOp;
            break;
        case 'Способствует ЖБОиП' :
            handler = createZbo;
            break;
    }

    return (
        <CUItem
            id={0}
            name={name}
            category={category}
            setName={setName}
            setCategory={setCategory}
            // @ts-ignore
            handler={handler}
            modalTitle='Добавить новый пункт'
            btnName='Добавить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalItemAdd;