import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import UsersStore from './store/UsersStore';
import ServiceStore from './store/ServiceStore';

import './style/style.sass';

type RootStateContextValue = {
  users: UsersStore;
  service: ServiceStore;
};

export const Context = createContext<RootStateContextValue>({} as RootStateContextValue);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Context.Provider value={{
    users: new UsersStore(),
    service: new ServiceStore()
  }}>
    <App />
  </Context.Provider>
);