import { $authHost } from ".";

export const createVideoCommon = async (title: string, link: number) => {
    const {data} = await $authHost.post('api/videos/common', {title, link});
    return data;
};

export const fetchVideosCommon = async () => {
    const {data} = await $authHost.get('api/videos/common');
    return data;
};

export const fetchOneVideoCommon = async (id: number) => {
    const {data} = await $authHost.get('api/videos/common/' + id);
    return data;
};

export const updateVideoCommon = async (id: number, title: string, link: number) => {
    const {data} = await $authHost.put('api/videos/common/' + id, {title, link});
    return data;
};

export const deleteVideoCommon = async (id: number) => {
    const {data} = await $authHost.delete('api/videos/common/' + id);
    return data;
};