import React from 'react';
import {Container} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';

import BackBlock from './BackBlock';
import {OP_ROUTE, ZBO_ROUTE} from '../../utils/consts';


import './methodBlock.sass';


const MethodBlock2: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container className="method">
            <Helmet>
                <title>Методика. Часть 2</title>
                <meta name="description" content="Методика. Часть 2" />
            </Helmet>

            <h3 className="method__title">Часть 2</h3>
            <p className="method__paragraph">Нам предстоит с тобой пройти путь становления свободным от зависимости. Хочу тебя предупредить - вначале будет тяжело (прими этот факт), но это та жертва, которую ты должен принести для достижения целей, связанных с ЖБОиП. Но ради чего приносить жертву? Какая здесь мотивация? Прежде всего, ТВОИ ответы на вопросы “Зачем мне это?” и “Чего я хочу достичь победив ОП?” - и есть твоя основная мотивация. Поэтому не ищи мотивации из вне, она внутри тебя. При этом хочу обратить твоё внимание, что если ты считаешь, что решение проблемы с ОП решит все твои жизненные проблемы - это не так. Но это значительно будет способствовать тому, чтобы ты стал человеком, способным решить такие проблемы. </p>
            <p className="method__paragraph">Что же будет способствовать тому, чтобы ты стал таким человеком?</p>
            <ul className="method__list"><b>Минусы ОП, от которых ты избавишься:</b>
                <li>Апатия, опустошонность</li>
                <li>Слабая энергетика </li>
                <li>Чувство стыда</li>
                <li>Страх реальности</li>
                <li>Подавленная чувствительность дофаминовых рецепторов</li>
                <li>Неспособность радоваться мелочам</li>
                <li>Низкий уровень тестостерона</li>
                <li>Неспособность заниматься делами</li>
                <li>Сексуальное напряжение</li>
                <li>Куколдизм</li>
                <li>Трата времени (на сам процесс и восстановление из состояния “овоща”)</li>
            </ul>
            <ul className="method__list"><b>Плюсы ЖБОиП, которые ты приобретёшь:</b>
                <li>Энергичное, бодрое состояние</li>
                <li>Повышение самооценки, уважение себя</li>
                <li>Избавление от стыда и чувства вины</li>
                <li>Повышение привлекательности в глазах женского пола</li>
                <li>Восстановится чувствительность дофаминовых рецепторов</li>
                <li>Способность радоваться мелочам</li>
                <li>Повышение уровня тестостерона</li>
                <li>Высвобождение времени</li>
            </ul>
            <ol className="method__list">Чтобы наше обучение проходило наиболее эффективно, напомню основные принципы:
                <li>Нельзя научить, можно научиться;</li>
                <li>Никто не может помочь человеку, пока он сам не захочет этого.</li>
            </ol>
            <p className="method__paragraph">Что же касается сложности и длительности нашего пути. К примеру, стать президентом, директором фирмы, классным лётчиком или создать прибыльный бизнес - не всё зависит от тебя на 100%, и требует реально огромных усилий и вложений (время, деньги, энергия, связи). А вот выбросить из своей жизни онанизм и порно - полностью зависит от тебя, хотя тоже требует вложений ресурсов. Время и упорство - наиболее важные. Без вложений нет приверженности.</p>
            <p className="method__paragraph">Эта часть посвящена твоей умственной работе, твоим воспоминаниям и наблюдениям. Сейчас в твоей жизни присутствует ОП, а наша цель перейти к ЖБОиП.  Я не говорю тебе, чтобы ты сразу бросил смотреть порно и дрочить, ведь если бы это было так просто, ты бы давно справился с этим сам, и не читал бы этот сайт.</p>
            <p className="method__paragraph">Чтобы перейти от ОП к ЖБОиП необходимо скорректировать свой образ жизни. Это не быстро, но двигаясь постепенно, маленькими шажочками мы сможем это сделать.</p>
            <h4 className="method__title">Задания:</h4>
            <p className="method__paragraph">1. Открой вкладку <a className="method__link" onClick={() => navigate(OP_ROUTE)}>"Способствует ОП"</a> и выписывай туда всё, что способствует ОП. Это может быть как в сам момент соблазна посмотреть порно (эротику / секси девчонок) / подрочить, так и за долго до такого момента, когда ты ещё и не задумывался, что можешь сорваться. </p>
            <ul className="method__list">Приведу примеры из своего опыта:
                <li>Один дома</li>
                <li>Безделие</li>
                <li>Залипание в Ютубе</li>
                <li>Сёрфинг в интернете</li>
                <li>Спать без трусов</li>
                <li>Фантазии о сексе</li>
                <li>Вспоминание сцен из порно</li>
                <li>Апатия, скука</li>
                <li>Гордыня</li>
                <li>Утренняя эрекция</li>
                <li>Трогать член</li>
                <li>Пища (мясо - усиливает возбуждение, сахар - ослабляет силу воли)</li>
                <li>...</li>
            </ul>
            <p className="method__paragraph">У тебя, конечно, свой набор компонентов. Пиши всё, что считаешь нужным. Это не значит, что если это произошло, то ты сразу сорвался. Нет. Но в твоей жизни были моменты, когда это событие способствовало тому, что ты сорвался. Это может быть располагающая обстановка или состояние. То есть эта ситуация (или деятельность) может быть и не связана напрямую с ОП, но у тебя был опыт, когда это являлось искрой, от которой загорался фитиль онанизма.</p>
            <p className="method__paragraph">2. Теперь открой вкладку <a className="method__link" onClick={() => navigate(ZBO_ROUTE)}>"Способствует ЖБОиП"</a>. Уверен, у тебя в жизни были моменты, когда ты был на 100% уверен, что сейчас ты не будешь ни смотреть порно, ни дрочить - тебе это нах не надо. Или, может были ситуации, когда ты был уже близок к срыву, но что-то помогло тебе устоять, и в тот момент ты одержал победу над похотью. Или ситуации, в которых просто невозможно заниматься ОП. Выписывай всё, что способствует ЖБОиП.</p>
            <ul className="method__list">Приведу примеры из своего опыта:
                <li>Вне дома</li>
                <li>Присутствие людей</li>
                <li>Тренировка</li>
                <li>Уборка</li>
                <li>Мотивационное видео</li>
                <li>Настрой: Сегодня я выстою!</li>
                <li>Мысли, не связанные с сексом и порно</li>
                <li>Ранний подъём</li>
                <li>Занятость</li>
                <li>Увлечённость какой-то деятельностью</li>
                <li>Ограниченность во времени</li>
                <li>...</li>
            </ul>
            <p className="method__paragraph">Это может быть деятельность, состояние или обстановка. Выписывай всё, что касается данной темы. Выписывай подряд, как приходят мысли. Можешь сортировать по категориям, но это не обязательно.</p>
            <p className="method__paragraph">Твоя работа заключается в том, чтобы написать довольно большой список. Ты можешь вначале выделить этому, к примеру, час сосредоточенной работы. А потом в течении дня, как будет возникать мысль, дописывать её. Этим процессом тебе предстоит заниматься длительное время. Но в ближайшие 2 дня постарайся уделить этому процессу достаточно времени и выполнить по максимуму. Тогда потом тебе нужно будет лишь дописывать свежие мысли, которые иногда будут приходить. А также, как только возникает ситуация, ведущая к срыву - вноси её в список.</p>

            <BackBlock />
        </Container>
    );
};

export default MethodBlock2;