import { $authHost } from ".";

export const createArticlePrivate = async (title: string, link: string) => {
    const {data} = await $authHost.post('api/articles/private', {title, link});
    return data;
};

export const fetchArticlesPrivate = async () => {
    const {data} = await $authHost.get('api/articles/private');
    return data;
};

export const fetchOneArticlePrivate = async (id: number) => {
    const {data} = await $authHost.get('api/articles/private/' + id);
    return data;
};

export const updateArticlePrivate = async (id: number, title: string, link: string) => {
    const {data} = await $authHost.put('api/articles/private/' + id, {title, link});
    return data;
};

export const deleteArticlePrivate = async (id: number) => {
    const {data} = await $authHost.delete('api/articles/private/' + id);
    return data;
};