import React, {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import { IElm } from '../../types/types';
import {METHODOLOGY_ROUTE} from '../../utils/consts';
import { Context } from '../..';

import './notesPage.sass';


const NotesPage: React.FC = () => {
    const {users} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);
    const navigate = useNavigate();
    const elms: IElm[] = [
        {
            id: 2,
            title: 'Часть 1',
            userId: 1
        },
        {
            id: 3,
            title: 'Часть 2',
            userId: 1
        },
        {
            id: 4,
            title: 'Часть 3',
            userId: 1
        },
        {
            id: 5,
            title: 'Часть 4',
            userId: 1
        },
        {
            id: 6,
            title: 'Часть 5',
            userId: 1
        },
        {
            id: 7,
            title: 'Часть 6',
            userId: 1
        },
    ];


    return (
        <Container className='notes'>
            <Helmet>
                <title>Записи</title>
                <meta name="Notes" content="Записи" />
            </Helmet>

            <h1 className='notes__title'>Мои записи</h1>
            <ul className='notes__list'>
                {elms.length && elms.map(elm =>
                    <li key={elm.id} className='notes__list_item' ><span onClick={() => navigate(METHODOLOGY_ROUTE + '/' + elm.id)}>{elm.title}</span></li>
                )}
            </ul>

            {users.role === "ADMIN" &&
                <Button variant={"outline-success"} className='notes__btn' onClick={() => setVisible(true)} >Добавить</Button>
            }
        </Container>
    );
};

export default NotesPage;