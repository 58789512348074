import { $authHost } from ".";

export const createZbo = async (name: string, category: string) => {
    const {data} = await $authHost.post('api/zbo', {name, category});
    return data;
};

export const fetchZbos = async () => {
    const {data} = await $authHost.get('api/zbo');
    return data;
};

export const fetchOneZbo = async (id: number) => {
    const {data} = await $authHost.get('api/zbo/' + id);
    return data;
};

export const updateZbo = async (id: number, name: string, category: string) => {
    const {data} = await $authHost.put('api/zbo/' + id, {name, category});
    return data;
};

export const deleteZbo = async (id: number) => {
    const {data} = await $authHost.delete('api/zbo/' + id);
    return data;
};