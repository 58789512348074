import React, {useState, useContext, useEffect} from 'react';

import { updateQuoteCommon } from '../../http/quotesCommonAPI';
import { updateQuotePrivate } from '../../http/quotesPrivateAPI';
import { Context } from '../..';
import { IQuote } from '../../types/types';
import CUQuote from '../CreateUpdate/CUQuote';

interface ModalQuoteAddProps {
    show: boolean;
    onHide: () => void;
    quoteItem: IQuote;
};


const ModalQuoteAdd: React.FC<ModalQuoteAddProps> = ({show, onHide, quoteItem}) => {
    const {users} = useContext(Context);
    const [quote, setQuote] = useState<string>('');    
    const [author, setAuthor] = useState<string>('');

    useEffect(() => {
        setQuote(quoteItem.quote);
        setAuthor(quoteItem.author);
    }, [show]);

    return (
        <CUQuote
            id={quoteItem.id}
            quote={quote}
            author={author}
            setQuote={setQuote}
            setAuthor={setAuthor}
            // @ts-ignore
            handler={users.role === "ADMIN" ? updateQuoteCommon : updateQuotePrivate}
            modalTitle='Обновить цитату'
            btnTitle='Обновить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalQuoteAdd;