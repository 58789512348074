import React from 'react';
import {Container, Button} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import './schedulePage.sass';

const SchedulePage: React.FC = () => {
    return (
        <Container className='op'>
            <Helmet>
                <title>Календарь</title>
                <meta name="description" content="Календарь" />
            </Helmet>           

            <div className='op__quote'>
                SchedulePage
            </div>
        </Container>
    );
};

export default SchedulePage;