import React from 'react';
import {Container} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import BackBlock from './BackBlock';

import './methodBlock.sass';


const MethodBlock5: React.FC = () => {
    return (
        <Container className="method">
            <Helmet>
                <title>Методика. Часть 5</title>
                <meta name="description" content="Часть 7" />
            </Helmet>

            <h3 className="method__title">Часть 5</h3>
            <p className="method__paragraph">Сегодня поговорим о срывах. По сути, они неизбежны на пути к полному освобождению от зависимости. Но важно правильно к ним относиться. Существуют разные мнения о том стоит ли считать дни или нет, как долго ты воздерживаешься. Я считаю, что этот вопрос у нас решён в Календаре - там всё наглядно видно. Даже не стоит специально считать, типа 3, 5, 10 дней воздержания. Просто заполняя календарь ежедневно, ты видишь как пустая графа ОП растёт - а значит ты всё делаешь правильно. Многие привыкли после срыва сбрасывать счётчик и начинать сначала, но это не продуктивно, ведь тем самым ты обесцениваешь все те усилия и время, когда ты жил без ОП, а это не так. Это время, пройденный тобой путь, никуда не исчезли, и на тот период принесли свои плоды, может пока скромные, но всё же.</p>
            <p className="method__paragraph">Более продуктивное отношение к срывам - это вместо "Всё пропало" и "Счётчик на ноль" - закономерное (ожидаемое) отклонение на пути к цели, которое указывает на то, какие коррективы надо внести. Пройденный путь никуда не делся. Просто ты сошёл с него. Осознай это и возвращайся на путь, где сошёл, а НЕ на старт. Пройденный путь, твой вклад, остался с тобой.</p>
            <p className="method__paragraph">Да, неприятно, когда сорвался, хотя до этого обещал себе, что этого не повторится. Но! Это путь, и он не обязан быть прямым. Есть и горы, и овраги. Есть и взлёты, и падения. Твоя цель жить без онанизма и порнографии стабильно, долго - так и иди к ней. А срывы на этом пути рассматривай как обратную связь на то, правильно ли ты идёшь к своей цели или где-то заплутал, схалтурил. Для этого каждый срыв стоит анализировать, с тем, чтобы извлечь из него пользу на будущее, и сегодняшнее поражение со временем обернуть в победу.</p>
            <ol className="method__list">Анализ срывов:
                <li>Что привело (причина)?</li>
                <li>С чего началось?</li>
                <li>Как я мог поступить в момент борьбы, чтобы выйти победителем?</li>
                <li>Мог ли я не доводить ситуацию до борьбы?</li>
                <li>Как поступлю в будущем в подобной ситуации?</li>
            </ol>
            <p className="method__paragraph">Если произошёл срыв, не стоит себя корить и бичевать - это не конструктивно и не приносит пользы, а вот самооценку ты себе точно загонишь под плинтус. Проанализируй срыв, ответив на 5 вопросов.</p>
            <p className="method__paragraph">А ты задумывался, что такое сперма? Что ты теряешь, когда сливаешь семя в унитаз? Какие ресурсы затрачивает организм, чтобы её произвести?</p>
            <p className="method__paragraph">Сперма (семя) - это мужская энергия, сила. Это энергия, дарующая жизнь.</p>
            <p className="method__paragraph">1 капля спермы соответствует 40 каплям крови, т.е. эквивалентна по содержанию в ней питательных и жизненно важных веществ, витаминов, энергетической ценности.</p>
            <p className="method__paragraph">1 мужской эякулят это примерно 5 мл жидкости, что эквивалентно 200 мл крови. Т.е. ты 1 раз слил, а это соответствует, что потерял питательных и жизненно важных веществ сколько содержится в 200 мл крови.</p>
            <p className="method__paragraph">Полный цикл созревания 1 спермотозойда составляет от 70 до 74 дней. И одновременно в организме мужчины зреет миллиард спермотозойдов.</p>
            <p className="method__paragraph">Сперма не может застаиваться, она не должна быть “выплюнута” из организма. Если в течении месяца выработанная сперма не будет израсходована, она будет переварена в организме, фагоцитоз. Никаких застоев не произойдёт, это миф. Природой заложены предохранительные клапаны в виде ночных полиции и утренних стояков.</p>
            <p className="method__paragraph">Перед важными выступлениями женщинам секс показан (т.к. они принимают семя), а мужчинам противопоказан (т.к. они теряют семя). Поэтому профессиональные спортсмены перед важными выступлениями отказывались полностью от секса.</p>
            <p className="method__paragraph">Теперь поговорим о сроках. Я считаю, что в борьбе с зависимостью не стоит ставить жёсткие временные рамки, типа перестать дрочить и смотреть порно к 31 мая / до Нового года / своего ДР, итд. Это не покупка авто или изучение английского - зависимость намного сложнее и коварнее. Поэтому стоит опираться на время, уделённое проработке данного вопроса. К примеру, есть утверждение, что, чтобы стать профессионалом в какой-угодно области, надо вложить в это развитие 10.000 часов. Так и здесь. Я не говорю про 10тыс, но определённое время стоит вложить. Желательно на первых порах хотя бы по полчаса-часу в день выделять на развитие себя в данной области. Например, на изучение механизмов зависимости, заполнение календаря, просмотр сайта, выписывать свои мысли по данной теме в Дневник, а также потребление мотивационного контента. Сделай для себя подборку мотивационных фильмов, роликов, книг, статей, песен. Всё это тебе пригодится. Пополняй НКД.</p>
            <p className="method__paragraph">Кстати, вдохновляюще действуют цитаты, можешь их тоже выписывать.</p>
            <ul>Приведу парочку:
                <li>Терпи и воздерживайся. НЕ удовлетворением своих праздных желаний достигается свобода, а избавлением себя от таких желаний. Эпиктет</li>
                <li>Избегай удовольствий, и они сами придут к тебе. Чжуан-Цзы</li>
                <li>Само презрение к наслаждению благодаря привычке становится высшим наслаждением. Диоген</li>
                <li>В каждом человеке идет борьба, очень похожая на борьбу двух волков. Один волк представляет зло — зависть, ревность, сожаление, эгоизм, похоть, ложь... Другой волк представляет добро — мир, любовь, надежду, истину, доброту, верность… Всегда побеждает тот волк, которого кормишь.</li>
            </ul>
            <p className="method__paragraph">И в завершении курса несколько напутственных слов:</p>
            <ol>
                <li>Веди ежедневно Календарь.</li>
                <li>Включай в него полезные привычки, которые хотел бы развить.</li>
                <li>Включай в него вредные привычки, от которых хотел бы избавиться.</li>
                <li>Периодически перечитывай “Зачем мне это”.</li>
                <li>Вкладывай свои “10.000 часов” в изучение данной темы.</li>
                <li>Воспринимай срывы как обратную связь, что надо скорректировать, а не сброс всё на ноль.</li>
                <li>То, что способствует ОП - делай реже, минимизируй.</li>
                <li>То, что способствует ЖБОиП - делай чаще.</li>
                <li>Развивай и укрепляй привычку бороться и побеждать.</li>
            </ol>

            <BackBlock />
        </Container>
    );
};

export default MethodBlock5;