import React, {useState} from 'react';

import CUNote from '../CreateUpdate/CUNote';
import { createNote } from '../../http/notesAPI';

interface ModalNoteAddProps {
    show: boolean;
    onHide: () => void;
};


const ModalNoteAdd: React.FC<ModalNoteAddProps> = ({show, onHide}) => {
    const [text, setText] = useState<string>('');

    return (
        <CUNote
            id={0}
            text={text}
            setText={setText}
            // @ts-ignore
            handler={createNote}
            modalTitle='Добавить запись'
            btnTitle='Добавить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalNoteAdd;