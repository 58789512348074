import React, {useState, useContext} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';

import { createImageCommon } from '../../http/imagesCommonAPI';
import { createImagePrivate } from '../../http/imagesPrivateAPI';
import { Context } from '../..';

interface ModalImageAddProps {
    show: boolean;
    onHide: () => void;
};


const ModalImageAdd: React.FC<ModalImageAddProps> = ({show, onHide}) => {
    const {users, service} = useContext(Context);
    const [image, setImage] = useState<File | null>(null);
    const handler = users.role === "ADMIN" ? createImageCommon : createImagePrivate;

    const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => { 
        const files: FileList | null = e.target.files;
        if (files) {
            if (files[0].size > 1048576) {
                return alert('Изображение должно быть менее 1мб')
            }
            // @ts-ignore 
            const fileExtension: string = files[0].name.split(".").at(-1);
            const allowedFileTypes: string[] = ["jpg", "jpeg", "png", "webp"];
            if (!allowedFileTypes.includes(fileExtension)) {
                return alert(`Расширение файла не поддерживается. Допустымые расширения: ${allowedFileTypes.join(", ")}`);
            }
    
            setImage(files[0]);
        }
    };

    const onClick = () => {
        if (!image) {
            return alert('Изображение необходимо загрузить');
        } 
        
        const formData = new FormData();
        formData.append('image', image);

        handler(formData)
            .then(() => {
                onHide();
                setImage(null);
                service.setToggle(!service.toggle);
            })
            .catch(err => alert(err.response.data.message));
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Добавить изображение
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <label htmlFor="file" className="mt-3">Загрузи изображение</label>       
                        <Form.Control                        
                            type="file"
                            accept="image/*"
                            onChange={selectFile}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"outline-success"} onClick={onClick}>Добавить</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalImageAdd;