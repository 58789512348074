import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Container, Button, Spinner} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import {JOURNAL_ROUTE, MATERIALS_ROUTE, NKD_ROUTE, OP_ROUTE, SCHEDULE_ROUTE, ZBO_ROUTE, EDGE_ROUTE} from '../../utils/consts';
import { IQuote } from '../../types/types';
import { fetchQuotesCommon } from '../../http/quotesCommonAPI';

import './mainPage.sass';


const MainPage: React.FC = () => {
    const navigate = useNavigate();
    const [quotes, setQuotes] = useState<IQuote[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchQuotesCommon()
            .then(data => {
                setQuotes(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, []);

    const rundomNumberInRange = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    const index: number = rundomNumberInRange(0, quotes.length - 1);

    return (
        <Container className="d-flex flex-column main">
            <Helmet>
                <title>ЖБОиП</title>
                <meta name="description" content="ЖБОиП" />
            </Helmet>           

            {loading ? <Spinner /> :
                <div className='main__quote'>
                    {quotes[index].quote} <br/> {quotes[index].author}
                </div>
            }

            <h2 className='main__title'>Что ты хочешь посмотреть?</h2>

            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => navigate(OP_ROUTE)}
                >
                Способствует ОП
            </Button>                   
            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => navigate(ZBO_ROUTE)}
                >
                Способствует ЖБОиП
            </Button>
            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => navigate(NKD_ROUTE)}
                >
                НКД
            </Button>
            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => navigate(MATERIALS_ROUTE)}
                >
                Мотивация и Материалы
            </Button>
            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => navigate(JOURNAL_ROUTE)}
                >
                Дневник
            </Button>
            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => navigate(SCHEDULE_ROUTE)}
                >
                Календарь
            </Button>
            <Button 
                variant={"outline-danger"} 
                className="mt-4 p-2 shadow"
                onClick={() => navigate(EDGE_ROUTE)}
                >
                На гране срыва
            </Button>            
        </Container>
    );
};

export default MainPage;