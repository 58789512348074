import React, {useState, useContext, useEffect} from 'react';
import {Container, Button, Spinner} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import {observer} from 'mobx-react-lite';

import List from '../../components/List/List';
import ListItemNote from '../../components/ListItemQuote/ListItemNote';
import ModalNoteAdd from '../../components/Modals/ModalNoteAdd';
import ModalNoteUpdate from '../../components/Modals/ModalNoteUpdate';
import { INote } from '../../types/types';
import { Context } from '../..';
import { fetchNotes, deleteNote } from '../../http/notesAPI';

import './journalPage.sass';


const JournalPage: React.FC = observer(() => {
    const {users, service} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [note, setNote] = useState<INote>({} as INote);
    const [notes, setNotes] = useState<INote[]>([]);

    useEffect(() => {
        fetchNotes().then(data => {
            setNotes(data);
            setLoading(false);
        });
    }, [service.toggle]);

    const removeItem = (item: INote) => {
        if (window.confirm('Ты действительно хочешь удалить эту запись?')) {
            deleteNote(item.id);
            service.setToggle(!service.toggle);
        }
    };

    const editItem = (item: INote) => {
        setNote(item);
        setVisibleUpdate(true);
    };

    if (users.role === 'ADMIN') {
        return <div></div>
    }


    return (
        <Container className='journal'>
            <Helmet>
                <title>Дневник</title>
                <meta name="description" content="Дневник" />
            </Helmet>

            <h3 className='journal__title'>Записи в дневник:</h3>
            <Button variant={"outline-success"} className='journal__btn' onClick={() => setVisible(true)} >Добавить</Button>
            {loading ? <Spinner /> :
                <div>
                    {notes.length ? 
                        <div>
                            <List 
                                items={notes}
                                renderItem={(item: INote) => 
                                    <ListItemNote 
                                        onDelete={() => removeItem(item)} 
                                        onEdit={() => editItem(item)} 
                                        item={item} 
                                        key={item.id}
                                    />
                                } 
                            />
                        </div>  
                    : <div></div>}
                </div>
            }
            <ModalNoteAdd show={visible} onHide={() => setVisible(false)} />
            <ModalNoteUpdate show={visibleUpdate} onHide={() => setVisibleUpdate(false)} note={note} />
        </Container>
    );
});

export default JournalPage;