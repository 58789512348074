import React, {useContext} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {observer} from 'mobx-react-lite'

import { MAIN_ROUTE, JOURNAL_ROUTE, MATERIALS_ROUTE, NKD_ROUTE, OP_ROUTE, SCHEDULE_ROUTE, ZBO_ROUTE, EDGE_ROUTE} from '../../utils/consts';
import { Context } from '../..';

import './asideNav.sass';


const AsideNav: React.FC = observer(() => {
    const {users} = useContext(Context);
    const navigate = useNavigate();
    const location = useLocation();
    const isMain = location.pathname === MAIN_ROUTE;

    return (
        <>
            {users.isAuth && !isMain ?
                <aside>
                    <div onClick={() => navigate(OP_ROUTE)} >Способствует ОП</div>
                    <div onClick={() => navigate(ZBO_ROUTE)} >Способствует ЖБОиП</div>
                    <div onClick={() => navigate(NKD_ROUTE)} >НКД</div>
                    <div onClick={() => navigate(MATERIALS_ROUTE)} >Мотивация</div>
                    <div onClick={() => navigate(JOURNAL_ROUTE)} >Дневник</div>
                    <div onClick={() => navigate(SCHEDULE_ROUTE)} >Календарь</div>
                    <div onClick={() => navigate(EDGE_ROUTE)} >На гране срыва</div>
                </aside>
                :
                <div></div>
            }
        </>
    );
});

export default AsideNav;