import { $authHost } from ".";

export const createProfile = async (profile: FormData) => {
    const {data} = await $authHost.post('api/profiles', profile);
    return data;
};

export const fetchProfile = async () => {
    const {data} = await $authHost.get('api/profiles');
    return data;
};

export const updateProfile = async (profile: FormData) => {
    const {data} = await $authHost.put('api/profiles', profile);
    return data;
};