import React from 'react';
import {Modal, Button, Form, Dropdown} from 'react-bootstrap';

interface ModalTermsProps {
    show: boolean;
    onHide: () => void;
};


const ModalTerms: React.FC<ModalTermsProps> = ({show, onHide}) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-name-vcenter">
                    Термины и сокращения
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    <li>ОП - Онанизм и Порнография</li>
                    <li>ЖБОиП - Жизнь Без Онанизма и Порнографии</li>
                    <li>НКД - Набор Конкретных Действий</li>
                </ul>
            </Modal.Body>
        </Modal>
    );
};

export default ModalTerms;