import React, {useContext} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import { Context } from '../..';

interface CUNoteProps {
    id: number;
    text: string;
    setText: (text: string) => void;
    handler: (id: number, text: string) => Promise<unknown>;
    modalTitle: string;
    btnTitle: string;
    show: boolean;
    onHide: () => void;
};


const CUNote: React.FC<CUNoteProps> = observer(({id, text, setText, handler, modalTitle, btnTitle, show, onHide}) => {
    const {service} = useContext(Context);

    const onClick = () => {
        if (!text.trim()) {
            return alert('Необхждимо внести запись');
        }

        if (btnTitle === 'Добавить') {
            // @ts-ignore
            handler(text)
                .then(() => {
                    onHide();
                    setText('');
                    service.setToggle(!service.toggle);
                })
                .catch(err => alert(err.response.data.message));
        } else if (btnTitle === 'Обновить') {
            handler(id, text)
                .then(() => {
                    onHide();
                    setText('');
                    service.setToggle(!service.toggle);
                })
                .catch(err => alert(err.response.data.message));
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-date-vcenter">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control as="textarea"
                        className="mt-3"
                        value={text}
                        onChange={e => setText(e.target.value)}
                        placeholder="Сделай запись"
                        maxLength={1000}
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={btnTitle === 'Добавить' ? "outline-success" : "outline-primary"} onClick={onClick}>{btnTitle}</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CUNote;