import React, {useState, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import {Helmet} from "react-helmet";

import {METHODOLOGY_ROUTE, PROFILE_ROUTE, MAIN_ROUTE, OP_ROUTE, MATERIALS_ROUTE, JOURNAL_ROUTE, SCHEDULE_ROUTE, EDGE_ROUTE} from '../../utils/consts';
import { Context } from '../..';
import ModalTerms from '../../components/Modals/ModalTerms';

import './manualPage.sass';


const ManualPage: React.FC = () => {
    const {users} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);

    return (
        <Container className="manual">
            <Helmet>
                <title>Инструкция</title>
                <meta name="description" content="Рекомендации" />
            </Helmet>
            
            <h2 className="manual__title">Как пользоваться сайтом</h2>
            <ol className="manual__list">
                <li className="manual__list_item" onClick={() => setVisible(true)}>Ознакомься с терминами и принятыми сокращениями</li>
                <li><NavLink className="manual__link" to={METHODOLOGY_ROUTE}>Ознакомься с методикой. Только полное прочтение методики даст понимание, как освободиться от зависимости</NavLink></li>
                <li><NavLink className="manual__link" to={PROFILE_ROUTE}>Заполни анкету. Она поможет тебе всегда помнить для чего ты прилагаешь усилия</NavLink></li>
                <li><NavLink className="manual__link" to={MAIN_ROUTE}>На главной странице и на боковой панели представлено меню быстрого доступа</NavLink></li>
                <li><NavLink className="manual__link" to={OP_ROUTE}>Разделы "Способствует ОП", "Способствует ЖБОиП", "НКД" ты полностью заполняешь сам, согласно рекомендациям из методики</NavLink></li>
                <li><NavLink className="manual__link" to={MATERIALS_ROUTE}>Раздел "Мотивация и Материалы" содержит мотивационный и информационный контент, который ты можешь дополнять</NavLink></li>
                <li><NavLink className="manual__link" to={JOURNAL_ROUTE}>В Дневник записываешь свои мысли, наблюдения, всё, что тебе кажется важным и относится к данной теме</NavLink></li>
                <li><NavLink className="manual__link" to={SCHEDULE_ROUTE}>В Календарь вносишь срывы</NavLink></li>
                <li><NavLink className="manual__link" to={EDGE_ROUTE}>На гране срыва - даёт конкретные рекомендации что делать, когда уже прижало...</NavLink></li>
            </ol>

            {users.role === "ADMIN" &&
                <Button variant={"outline-success"} className='manual__btn' onClick={() => setVisible(true)} >Добавить пункт</Button>
            }

            <ModalTerms show={visible} onHide={() => setVisible(false)} />
        </Container>
    );
};

export default ManualPage;