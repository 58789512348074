import { $authHost } from ".";

export const createQuotePrivate = async (quote: string, author: string) => {
    const {data} = await $authHost.post('api/quotes/private', {quote, author});
    return data;
};

export const fetchQuotesPrivate = async () => {
    const {data} = await $authHost.get('api/quotes/private');
    return data;
};

export const fetchOneQuotePrivate = async (id: number) => {
    const {data} = await $authHost.get('api/quotes/private/' + id);
    return data;
};

export const updateQuotePrivate = async (id: number, quote: string, author: string) => {
    const {data} = await $authHost.put('api/quotes/private/' + id, {quote, author});
    return data;
};

export const deleteQuotePrivate = async (id: number) => {
    const {data} = await $authHost.delete('api/quotes/private/' + id);
    return data;
};