import { $authHost } from ".";

export const createImageCommon = async (image: FormData) => {
    const {data} = await $authHost.post('api/images/common', image);
    return data;
};

export const fetchImagesCommon = async () => {
    const {data} = await $authHost.get('api/images/common');
    return data;
};

export const deleteImageCommon = async (id: number) => {
    const {data} = await $authHost.delete('api/images/common/' + id);
    return data;
};