import { $authHost } from ".";

export const createOp = async (name: string, category: string) => {
    const {data} = await $authHost.post('api/op', {name, category});
    return data;
};

export const fetchOps = async () => {
    const {data} = await $authHost.get('api/op');
    return data;
};

export const fetchOneOp = async (id: number) => {
    const {data} = await $authHost.get('api/op/' + id);
    return data;
};

export const updateOp = async (id: number, name: string, category: string) => {
    const {data} = await $authHost.put('api/op/' + id, {name, category});
    return data;
};

export const deleteOp = async (id: number) => {
    const {data} = await $authHost.delete('api/op/' + id);
    return data;
};