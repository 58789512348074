import React, {useContext, useState} from 'react';
import {Container, Button} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { Context } from '../..';

import './feedbackPage.sass';


const FeedbackPage: React.FC = () => {
    const {users} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);

    return (
        <Container className="feedback">
            <Helmet>
                <title>Обратная связь</title>
                <meta name="description" content="Обратная связь" />
            </Helmet>   

            <p>Хочу тебя попросить написать отзыв на методику. Для меня это важно. Чтобы другие ребята могли ознакомиться с  методикой и тем самым побороть свою зависимость. Отзыв будет размещён на сайте. Отзыв анонимный. В отзыве укажи свой возраст и стаж.</p>
            <p>Отзыв присылай на почту: <a href='mailto:a@bezonan.ru' className="feedback__link"> a@bezonan.ru</a></p>
            <p>Если у тебя есть желание, можешь отблагодарить любой комфортной для тебя суммой на карту: 1234 4567 8901 2345</p>

            <div>Отзывы:</div>
            <div>здесь пока ничего нет...</div>

            {users.role === "ADMIN" &&
                <div className='feedback__btns'>
                    <Button variant={"outline-success"} className='feedback__btn' onClick={() => setVisible(true)} >Добавить озыв</Button>
                    <Button variant={"outline-primary"} className='feedback__btn' onClick={() => setVisible(true)} >Изменить реквизиты</Button>
                </div>
            }
        </Container>
    );
};

export default FeedbackPage;