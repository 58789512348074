import { $authHost } from ".";

export const createVideoPrivate = async (title: string, link: string) => {
    const {data} = await $authHost.post('api/videos/private', {title, link});
    return data;
};

export const fetchVideosPrivate = async () => {
    const {data} = await $authHost.get('api/videos/private');
    return data;
};

export const fetchOneVideoPrivate = async (id: number) => {
    const {data} = await $authHost.get('api/videos/private/' + id);
    return data;
};

export const updateVideoPrivate = async (id: number, title: string, link: string) => {
    const {data} = await $authHost.put('api/videos/private/' + id, {title, link});
    return data;
};

export const deleteVideoPrivate = async (id: number) => {
    const {data} = await $authHost.delete('api/videos/private/' + id);
    return data;
};