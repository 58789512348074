import { ADMIN_ROUTE, ARTICLES_ROUTE, AUDIO_ROUTE, EDGE_ROUTE, FAILURE_ROUTE, FEEDBACK_ROUTE, FIRST_ROUTE, LOGIN_ROUTE, 
    REGISTER_ROUTE, MAIN_ROUTE, OP_ROUTE, SCHEDULE_ROUTE, NKD_ROUTE, MATERIALS_ROUTE, 
    IMAGES_ROUTE, NOTES_ROUTE, QUOTES_ROUTE, VIDEO_ROUTE, JOURNAL_ROUTE, 
    PROFILE_ROUTE, METHODOLOGY_ROUTE, TABLE_ROUTE, MANUAL_ROUTE, NOTFOUND_ROUTE, ZBO_ROUTE } from "./utils/consts";
import {AdminPage, ArticlesPage, AudioPage, AuthPage, EdgePage, FailurePage, FeedbackPage, FirstPage, JournalPage, ImagesPage, MainPage, 
    ManualPage, MaterialsPage, MethodologyPage, MethodPage, NkdPage, NotesPage, OpPage, Page404, ProfilePage, 
    QuotesPage, SchedulePage, VideoPage, ZboPage} from './pages';

export const authRoutes = [
    {
        path: ARTICLES_ROUTE,
        Component: ArticlesPage
    },
    {
        path: AUDIO_ROUTE,
        Component: AudioPage
    },
    {
        path: EDGE_ROUTE,
        Component: EdgePage
    },
    {
        path: FEEDBACK_ROUTE,
        Component: FeedbackPage
    },
    {
        path: FAILURE_ROUTE,
        Component: FailurePage
    },
    {
        path: JOURNAL_ROUTE,
        Component: JournalPage
    },
    {
        path: IMAGES_ROUTE,
        Component: ImagesPage
    }, 
    {
        path: MAIN_ROUTE,
        Component: MainPage
    }, 
    {
        path: MANUAL_ROUTE,
        Component: ManualPage
    },
    {
        path: MATERIALS_ROUTE,
        Component: MaterialsPage
    }, 
    {
        path: METHODOLOGY_ROUTE,
        Component: MethodologyPage
    },
    {
        path: METHODOLOGY_ROUTE + '/:id',
        Component: MethodPage
    },
    {
        path: NKD_ROUTE,
        Component: NkdPage
    },
    {
        path: NOTES_ROUTE,
        Component: NotesPage
    },
    {
        path: NOTFOUND_ROUTE,
        Component: Page404
    },
    {
        path: OP_ROUTE,
        Component: OpPage
    }, 
    {
        path: PROFILE_ROUTE,
        Component: ProfilePage
    },
    {
        path: QUOTES_ROUTE,
        Component: QuotesPage
    },
    {
        path: SCHEDULE_ROUTE,
        Component: SchedulePage
    }, 
    {
        path: VIDEO_ROUTE,
        Component: VideoPage
    },
    {
        path: ZBO_ROUTE,
        Component: ZboPage
    }, 
    
];

export const publicRoutes = [
    {
        path: FIRST_ROUTE,
        Component: FirstPage
    },
    {
        path: LOGIN_ROUTE,
        Component: AuthPage
    },
    {
        path: REGISTER_ROUTE,
        Component: AuthPage
    }
];

export const adminRoute = {
    path: ADMIN_ROUTE,
    Component: AdminPage
};