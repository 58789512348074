import { $authHost } from ".";

export const createImagePrivate = async (image: FormData) => {
    const {data} = await $authHost.post('api/images/private', image);
    return data;
};

export const fetchImagesPrivate = async () => {
    const {data} = await $authHost.get('api/images/private');
    return data;
};

export const deleteImagePrivate = async (id: number) => {
    const {data} = await $authHost.delete('api/images/private/' + id);
    return data;
};