import { $authHost } from ".";

export const createNkd = async (name: string, category: string) => {
    const {data} = await $authHost.post('api/nkd', {name, category});
    return data;
};

export const fetchNkds = async () => {
    const {data} = await $authHost.get('api/nkd');
    return data;
};

export const fetchOneNkd = async (id: number) => {
    const {data} = await $authHost.get('api/nkd/' + id);
    return data;
};

export const updateNkd = async (id: number, name: string, category: string) => {
    const {data} = await $authHost.put('api/nkd/' + id, {name, category});
    return data;
};

export const deleteNkd = async (id: number) => {
    const {data} = await $authHost.delete('api/nkd/' + id);
    return data;
};