export const ADMIN_ROUTE: string = '/admin';
export const ARTICLES_ROUTE: string = '/articles';
export const AUDIO_ROUTE: string = '/audio';
export const EDGE_ROUTE: string = '/edge';
export const FAILURE_ROUTE: string = '/failure';
export const FEEDBACK_ROUTE: string = '/feedback';
export const FIRST_ROUTE: string = '/';
export const IMAGES_ROUTE: string = '/images';
export const JOURNAL_ROUTE: string = '/journal';
export const LOGIN_ROUTE: string = '/login';
export const MAIN_ROUTE: string = '/main';
export const MANUAL_ROUTE: string = '/manual';
export const MATERIALS_ROUTE: string = '/materials';
export const METHODOLOGY_ROUTE: string = '/methodology';
export const NKD_ROUTE: string = '/nkd';
export const NOTES_ROUTE: string = '/notes';
export const NOTFOUND_ROUTE: string = '/404';
export const OP_ROUTE: string = '/op';
export const PROFILE_ROUTE: string = '/profile';
export const QUOTES_ROUTE: string = '/quotes';
export const REGISTER_ROUTE: string = '/register';
export const SCHEDULE_ROUTE: string = '/schedule';
export const TABLE_ROUTE: string = '/table';
export const VIDEO_ROUTE: string = '/video';
export const ZBO_ROUTE: string = '/zbo';