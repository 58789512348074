import React, {useState, useEffect, useContext} from 'react';
import {Container, Button} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import List from '../List/List';
import ListItem from '../ListItem/ListItem';
import ModalItemAdd from '../Modals/ModalItemAdd';
import ModalItemUpdate from '../Modals/ModalItemUpdate';
import { IItem } from '../../types/types';
import { Context } from '../..';

import './itemsComponent.sass';

interface ItemsComponentProps {
    title: string;
    advice: string;
    items: IItem[];
    handler: (id: number) => void;
};

const ItemsComponent: React.FC<ItemsComponentProps> = ({title, advice, items, handler}) => {
    const {service} = useContext(Context);
    const [filter, setFilter] = useState<string>('');
    const [visibleItems, setVisibleItems] = useState<IItem[]>([]);
    const [visibleAdd, setVisibleAdd] = useState<boolean>(false);
    const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
    const [item, setItem] = useState<IItem>({} as IItem);

    useEffect(() => {
        setVisibleItems(sort(filterPost(items, filter)));
    }, [items, filter]);

    function filterPost(items: IItem[], filter: string) {
        switch (filter) {
            case 'Деятельность':
                return items.filter(item => item.category === 'Деятельность');
            case 'Образ жизни':
                return items.filter(item => item.category === 'Образ жизни');
            case 'Ситуация':
                return items.filter(item => item.category === 'Ситуация');
            case 'Состояние':
                return items.filter(item => item.category === 'Состояние');
            case 'Мысли':
                return items.filter(item => item.category === 'Мысли');
            case 'Все':
                return items;
            default:
                return items;
        }
    };

    function sort(items: IItem[]) {        
        let sortItems: IItem[] = [];

        sortItems = [...items].sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });

        return sortItems;
    };

    const removeItem = (item: IItem) => {
        if (window.confirm('Ты действительно хочешь удалить этот пункт?')) {
            handler(item.id);
            service.setToggle(!service.toggle);
        }
    };

    const editItem = (item: IItem) => {
        setItem(item);
        setVisibleUpdate(true);
    };


    return (
        <Container className='item'>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={title} />
            </Helmet>           

            <h1 className='item__title'>{title}</h1>
            <Button 
                variant={"outline-secondary"} 
                className="item__btn shadow"
                onClick={() => setVisibleAdd(true)}
                >
                Добавить пункт
            </Button>

            <div className='filter'>
                <button className='filter__btn' onClick={(e) => setFilter('Деятельность')}>Деятельность</button>
                <button className='filter__btn' onClick={(e) => setFilter('Образ жизни')}>Образ жизни</button>
                <button className='filter__btn' onClick={(e) => setFilter('Ситуация')}>Ситуация</button>
                <button className='filter__btn' onClick={(e) => setFilter('Состояние')}>Состояние</button>
                <button className='filter__btn' onClick={(e) => setFilter('Мысли')}>Мысли</button>
                <button className='filter__btn' onClick={(e) => setFilter('Все')}>Все</button>
            </div>

            <h2 className='item__subtitle'>{advice}</h2>
            <List 
                items={visibleItems}
                renderItem={(item: IItem) => 
                    <ListItem 
                        onDelete={() => removeItem(item)} 
                        onEdit={() => editItem(item)} 
                        item={item} 
                        key={item.id}
                    />
                } 
            />
            <ModalItemAdd show={visibleAdd} onHide={() => setVisibleAdd(false)} pageTitle={title} />
            <ModalItemUpdate show={visibleUpdate} onHide={() => setVisibleUpdate(false)} item={item} pageTitle={title} />
        </Container>
    );
};

export default ItemsComponent;