import React, {useContext} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';

import { Context } from '../..';

interface CUQuoteProps {
    id: number;
    quote: string;
    author: string;
    setQuote: (quote: string) => void;
    setAuthor: (author: string) => void;
    handler: (id: number, quote: string, author: string) => Promise<unknown>;
    modalTitle: string;
    btnTitle: string;
    show: boolean;
    onHide: () => void;
};


const CUQuote: React.FC<CUQuoteProps> = ({id, quote, author, setQuote, setAuthor, handler, modalTitle, btnTitle, show, onHide}) => {
    const {service} = useContext(Context);

    const onClick = () => {
        if (!quote.trim()) {
            return alert('Цитата обязательна для заполнения');
        }

        if (btnTitle === 'Добавить') {
            // @ts-ignore
            handler(quote, author)
                .then(() => {
                    onHide();
                    setQuote('');
                    setAuthor('');
                    service.setToggle(!service.toggle);
                })
                .catch(err => alert(err.response.data.message));
        } else if (btnTitle === 'Обновить') {
            handler(id, quote, author)
                .then(() => {
                    onHide();
                    setQuote('');
                    setAuthor('');
                    service.setToggle(!service.toggle);
                })
                .catch(err => alert(err.response.data.message));
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-quote-vcenter">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control as="textarea"
                        className="mt-3"
                        value={quote}
                        onChange={e => setQuote(e.target.value)}
                        placeholder="Введи цитату"
                        maxLength={700}
                    />
                    <Form.Control
                        className="mt-3"
                        value={author}
                        onChange={e => setAuthor(e.target.value)}
                        placeholder="Введи автора"
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={btnTitle === 'Добавить' ? "outline-success" : "outline-primary"} onClick={onClick}>{btnTitle}</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CUQuote;