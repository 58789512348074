import React from 'react';
import {Container} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import BackBlock from './BackBlock';

import './methodBlock.sass';


const MethodBlock6: React.FC = () => {
    return (
        <Container className="method">
            <Helmet>
                <title>Методика. Часть 6</title>
                <meta name="description" content="Часть 8" />
            </Helmet>

            <h3 className="method__title">Часть 6</h3>
            <p className="method__paragraph">Краткое, тезисное изложение того, что мы прошли за этот курс.</p>
            <p className="method__paragraph"><b>Методика как прийти к ЖБОиП и жить так стабильно, устойчиво</b></p>
            <ol>
                <li>Ответь на вопросы:
                    <ul>
                        <li>Зачем мне это? Чего я хочу достигнуть, решив эту проблему?</li>
                        <li>Как победа над ОП поможет достичь моей цели?</li>
                    </ul>
                </li>
                <li>Полезные убеждения
                    <ul>
                        <li>Отношение к женскому телу</li>
                        <li>Отношение к семени</li>
                        <li>Отношение к ОП</li>
                        <li>Отношение к срывам</li>
                    </ul>
                </li>
                <li>Знать, что способствует ОП - делать как можно реже, минимизировать</li>
                <li>Знать, что способствует ЖБОиП - делать чаще</li>
                <li>Выработать привычку “Бороться и побеждать”</li>
                <li>Набор конкретных действий в момент борьбы</li>
                <li>"Десять тысяч часов"
                    <ul>
                        <li>Изучай механизмы зависимости</li>
                        <li>Пополняй список того, что влияет</li>
                        <li>Пополняй НКД</li>
                        <li>Потребление мотивационного контента</li>
                    </ul>
                </li>
                <li>Анализ срывов
                    <ul>
                        <li>Что привело? Причина</li>
                        <li>С чего началось?</li>
                        <li>Как я мог поступить в момент борьбы, чтобы выйти победителем?</li>
                        <li>Мог ли я не доводить ситуацию до борьбы?</li>
                        <li>Как поступлю в будущем в подобной ситуации?</li>
                    </ul>
                </li>
            </ol>
            <p className="method__paragraph">Вот и всё. Дальше зависит от тебя, как сильно ты хочешь жить без онанизма и порно стабильно, насколько ты готов следовать рекомендациям.</p>
            <p className="method__paragraph">Для достижения любой цели необходимо принести требуемую жертву, которая устанавливается не нами. В нашем случае, требуемая жертва - это уделённое время данной проблеме, развитие полезных привычек и искоренение вредных, следование рекомендациям, а также отказ от сиюминутного удовольствия (вредного и кратковременного) в пользу будущего удовлетворения (полезного и долговременного).</p>
            <p className="method__paragraph">Хочу тебя попросить написать отзыв на методику. Для меня это важно. Чтобы другие ребята могли ознакомиться с моей методикой и тем самым побороть свою зависимость. Отзыв будет размещён на сайте. Отзыв анонимный, только укажи имя (можешь вымышленное) и возраст (реальный).</p>
            
            <BackBlock />
        </Container>
    );
};

export default MethodBlock6;