import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Helmet} from 'react-helmet';
import {Container, Modal, Button, Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import { IImage } from '../../types/types';
import { fetchImagesCommon, deleteImageCommon } from '../../http/imagesCommonAPI';
import { fetchImagesPrivate, deleteImagePrivate } from '../../http/imagesPrivateAPI';
import { MATERIALS_ROUTE } from '../../utils/consts';
import { Context } from '../..';
import ModalImageAdd from '../../components/Modals/ModalImageAdd';
import CarouselFade from '../../components/Carousel/Carousel';

import './imagesPage.sass';


const ImagesPage: React.FC = observer(() => {
    const navigate = useNavigate();
    const {users, service} = useContext(Context);
    const [show, setShow] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [images, setImages] = useState<IImage[]>([]);
    const [userImages, setUserImages] = useState<IImage[]>([]);
    const [totalImages, setTotalImages] = useState<IImage[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [hover, setHover] = useState<boolean>(false);
    const [image, setImage] = useState<IImage>({} as IImage);

    useEffect(() => {
        fetchImagesPrivate().then(data => setUserImages(data));
        fetchImagesCommon()
            .then(data => {
                setImages(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, [service.toggle]);

    useEffect(() => {
        setTotalImages([...images, ...userImages]);
    }, [images, userImages]);

    const selectImage = (image: IImage) => {
        setImage(image);
        setShow(prev => !prev);
    };

    const removeImage = (id: number) => {
        if (window.confirm(`Ты действительно хочешь удалить изображение?`)) {
            if (users.role === "ADMIN") {
                deleteImageCommon(id).then(() => service.setToggle(!service.toggle));
            } else {
                deleteImagePrivate(id).then(() => service.setToggle(!service.toggle));
            }
        }    
    };

    if (loading) {
        return <Spinner />
    }

    return (
        <Container className='images'>
            <Helmet>
                <title>Картинки</title>
                <meta name="description" content="Материалы" />
            </Helmet>
            
            {images.length ? images.map(image =>
                <div className='images__wrapper'
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <img 
                        src={process.env.REACT_APP_API_URL + image.image} 
                        alt={"image" + image.id} 
                        key={image.id} 
                        className='images__item' 
                        onClick={() => selectImage(image)}
                    />
                    {users.role === "ADMIN" && 
                        <Button 
                            variant="danger" 
                            className='images__btn' 
                            onClick={() => removeImage(image.id)}
                            style={{visibility: hover ? 'visible' : 'hidden'}}
                            >Удалить
                        </Button>
                    }
                </div>
            ) : ''}

            <Button variant={"outline-success"} onClick={() => setVisible(true)} >Добавить</Button>                 
            <Button variant={"outline-secondary"} onClick={() => navigate(MATERIALS_ROUTE)} >Назад</Button>

            {users.role !== "ADMIN" && userImages.length ? userImages.map(image =>
                <div className='images__wrapper'
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <img 
                        src={process.env.REACT_APP_API_URL + image.image} 
                        alt={"image" + image.id} 
                        key={image.id} 
                        className='images__item' 
                        onClick={() => selectImage(image)}
                    />
                    <Button 
                        variant="danger" 
                        className='images__btn' 
                        onClick={() => removeImage(image.id)}
                        style={{visibility: hover ? 'visible' : 'hidden'}}
                        >Удалить
                    </Button>
                </div>
            ) : ''}

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="xl"
                centered
            >
                <Modal.Body>
                    {/* <img className="images__item_large" src={process.env.REACT_APP_API_URL + image.image} alt={"image" + image.id} /> */}
                    <CarouselFade images={totalImages} id={image.id - 1} />
                </Modal.Body>
            </Modal>
            <ModalImageAdd show={visible} onHide={() => setVisible(false)} />
        </Container>
    );
});

export default ImagesPage;