import React from 'react';
import { useNavigate } from 'react-router-dom';
import {Button} from 'react-bootstrap';

import { METHODOLOGY_ROUTE } from '../../utils/consts';


const BackBlock: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Button variant={"outline-secondary"} onClick={() => navigate(METHODOLOGY_ROUTE)} >Назад</Button>
        </>
    );
};

export default BackBlock;