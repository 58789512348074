import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import { IElm } from '../../types/types';
import {METHODOLOGY_ROUTE} from '../../utils/consts';

import './methodologyPage.sass';


const MethodologyPage: React.FC = () => {
    const [toggle, setToggle] = useState<boolean>(false);
    const navigate = useNavigate();
    const elms: IElm[] = [
        {
            id: 1,
            title: 'Вступительное слово',
            userId: 1
        },
        {
            id: 2,
            title: 'Часть 1',
            userId: 1
        },
        {
            id: 3,
            title: 'Часть 2',
            userId: 1
        },
        {
            id: 4,
            title: 'Часть 3',
            userId: 1
        },
        {
            id: 5,
            title: 'Часть 4',
            userId: 1
        },
        {
            id: 6,
            title: 'Часть 5',
            userId: 1
        },
        {
            id: 7,
            title: 'Часть 6',
            userId: 1
        },
    ];

    const elms2: IElm[] = [
        {
            id: 1,
            title: 'Понимать Зачем мне это?',
            userId: 1
        },
        {
            id: 2,
            title: 'Полезные убеждения',
            userId: 1
        },
        {
            id: 3,
            title: 'Знать, что способствует ОП - делать как можно реже, минимизировать',
            userId: 1
        },
        {
            id: 4,
            title: 'Знать, что способствует ЖБОиП - делать чаще',
            userId: 1
        },
        {
            id: 5,
            title: 'Выработать привычку “Бороться и побеждать”',
            userId: 1
        },
        {
            id: 6,
            title: 'НКД в момент борьбы',
            userId: 1
        },
        {
            id: 7,
            title: '10000 часов',
            userId: 1
        },
        {
            id: 8,
            title: 'Анализ срывов',
            userId: 1
        }
    ];

    const onToggle = () => setToggle(!toggle);

    return (
        <Container className='methodology'>
            <Helmet>
                <title>Методика</title>
                <meta name="Methodology" content="Методика" />
            </Helmet>

            <h1 className='methodology__title'>Методика жить без онанизма и порно стабильно</h1>
            <ul className='methodology__list'>
                {elms.length && elms.map(elm =>
                    <li key={elm.id} className='methodology__list_item' ><span onClick={() => navigate(METHODOLOGY_ROUTE + '/' + elm.id)}>{elm.title}</span></li>
                )}
            </ul>

            <h3 className='methodology__subtitle' onClick={onToggle}>Ключевые тезисы методики</h3>
            {toggle &&
                <ul className='methodology__list'>
                    {elms2.length && elms2.map(elm =>
                        <li key={elm.id} className='methodology__list_item'>{elm.title}</li>
                    )}
                </ul>
            }
        </Container>
    );
};

export default MethodologyPage;