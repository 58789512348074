import React, {useState, useEffect} from 'react';

import { updateNkd } from '../../http/nkdAPI';
import { updateOp } from '../../http/opAPI';
import { updateZbo } from '../../http/zboAPI';
import { IItem } from '../../types/types';
import CUItem from '../CreateUpdate/CUItem';

interface ModalItemUpdateProps {
    show: boolean;
    onHide: () => void;
    item: IItem;
    pageTitle: string;
};


const ModalItemUpdate: React.FC<ModalItemUpdateProps> = ({show, onHide, item, pageTitle}) => {
    const [name, setName] = useState<string>('');    
    const [category, setCategory] = useState<string>('');
    let handler;

    useEffect(() => {
        setName(item.name);
        setCategory(item.category);
    }, [show]);

    switch (pageTitle) {
        case 'Набор конкретный действй' :
            handler = updateNkd;
            break;
        case 'Способствует ОП' :
            handler = updateOp;
            break;
        case 'Способствует ЖБОиП' :
            handler = updateZbo;
            break;
    }

    return (
        <CUItem
            id={item.id}
            name={name}
            category={category}
            setName={setName}
            setCategory={setCategory}
            // @ts-ignore
            handler={handler}
            modalTitle='Обновить пункт'
            btnName='Обновить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalItemUpdate;