import { $authHost } from ".";

export const createArticleCommon = async (title: string, link: number) => {
    const {data} = await $authHost.post('api/articles/common', {title, link});
    return data;
};

export const fetchArticlesCommon = async () => {
    const {data} = await $authHost.get('api/articles/common');
    return data;
};

export const fetchOneArticleCommon = async (id: number) => {
    const {data} = await $authHost.get('api/articles/common/' + id);
    return data;
};

export const updateArticleCommon = async (id: number, title: string, link: number) => {
    const {data} = await $authHost.put('api/articles/common/' + id, {title, link});
    return data;
};

export const deleteArticleCommon = async (id: number) => {
    const {data} = await $authHost.delete('api/articles/common/' + id);
    return data;
};