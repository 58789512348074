import { $authHost } from ".";

export const createQuoteCommon = async (quote: string, author: number) => {
    const {data} = await $authHost.post('api/quotes/common', {quote, author});
    return data;
};

export const fetchQuotesCommon = async () => {
    const {data} = await $authHost.get('api/quotes/common');
    return data;
};

export const fetchOneQuoteCommon = async (id: number) => {
    const {data} = await $authHost.get('api/quotes/common/' + id);
    return data;
};

export const updateQuoteCommon = async (id: number, quote: string, author: number) => {
    const {data} = await $authHost.put('api/quotes/common/' + id, {quote, author});
    return data;
};

export const deleteQuoteCommon = async (id: number) => {
    const {data} = await $authHost.delete('api/quotes/common/' + id);
    return data;
};