import React from 'react';
import {Container} from 'react-bootstrap';
import {Helmet} from "react-helmet";

import BackBlock from './BackBlock';

import './methodBlock.sass';


const MethodBlock3: React.FC = () => {
    return (
        <Container className="method">
            <Helmet>
                <title>Методика. Часть 3</title>
                <meta name="description" content="Часть 3" />
            </Helmet>

            <h3 className="method__title">Часть 3</h3>
            <p className="method__paragraph">В прошлой части ты получил задание выписывать всё, что касается ОП и ЖБОиП в органайзер. Ты выписывал в случайном порядке, по ходу как приходили мысли. Сегодня мы структурируем эту информацию. Ты, наверное, уже сам заметил, что некоторые составляющие относятся к общим категориям. На своём примере я объясню, чтобы тебе было понятнее.</p>
            <p className="method__paragraph">У меня есть такие составляющие, как “Режим дня”, “Пища”, “Одежда”, “Речь” - я все их объединяю в одну категорию “Образ Жизни”.</p>
            <p className="method__paragraph">Далее, такие составляющие, как “Стресс”, “Апатия”, “Скука”, “Слабость” - я объединяю в категорию “Состояние”.</p>
            <p className="method__paragraph">“Просмотр Ютуба”, “Залипание в телефоне” - в категорию “Деятельность”. И так далее.</p>
            <p className="method__paragraph">У тебя могут быть похожие категории, или какие-то свои. И сейчас тебе предстоит над этим поработать.</p>
            <p className="method__paragraph">Сначала проработай список того, что способствует ОП, а затем приступай к структуризации того, что способствует ЖБОиП.</p>
            <p className="method__paragraph">В результате твоей деятельности у тебя будут 2 структурированных списка: “Способствует ОП” и “Способствует ЖБОиП”. Теперь будем постепенно изменять твой образ жизни в лучшую сторону, в сторону ЖБОиП. Я не призываю тебя отказаться от того, что способствует ОП и делать только то,  что способствует ЖБОиП. В этом нет необходимости, да и не получится. Но тебе стоит стать избирательным, более осознанно подходить к своей деятельности. Ибо Образ Жизни, твои привычки, твоя регулярная деятельность - вот что определяет достигнешь ты успеха на этом пути или нет. </p>
            <p className="method__paragraph">“Мелочи не играют решающей роли - они решают всё!”(с)</p>

            <h4 className="method__title">Задания:</h4>
            <ol className="method__list">
                <li>Что способствует ОП - делай реже, минимизируй.</li>
                <li>Что способствует ЖБОиП - делай чаще.</li>
                <li>Продолжай пополнять списки.</li>
            </ol>

            <BackBlock />
        </Container>
    );
};

export default MethodBlock3;