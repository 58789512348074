import React from 'react';
import { Card } from 'react-bootstrap';

import { IQuote } from '../../types/types';

import './listItemQuote.sass';

interface ListItemQuoteProps {
    item: IQuote;
    onDelete: (item: IQuote) => void;
    onEdit: (item: IQuote) => void;
    role: string;
};


const ListItemQuote: React.FC<ListItemQuoteProps> = ({item, onDelete, onEdit, role}) => {
    return (
        <Card className="quote-item" >
            <div>
                <div className="quote-item__quote">{item.quote}</div>
                <div className="quote-item__author">{item.author}</div>
            </div>
            {role === "ADMIN" &&
                <div>
                    <i className="bi bi-pencil-fill quote-item__icon" onClick={() => onEdit(item)}></i>
                    <i className="bi bi-trash3-fill quote-item__icon" onClick={() => onDelete(item)}></i>
                </div>
            }
        </Card>      
    );
};

export default ListItemQuote;