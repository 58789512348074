import {makeAutoObservable} from 'mobx';

export default class UsersStore {
    _isAuth: boolean;
    _role: string;

    constructor() {
       this._isAuth = false;
       this._role = '';
       makeAutoObservable(this); 
    };

    setIsAuth(bool: boolean) {
        this._isAuth = bool;
    };
    setRole(role: string) {
        this._role = role;
    };

    get isAuth() {
        return this._isAuth;
    };
    get role() {
        return this._role;
    };
};