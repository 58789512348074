import React, {useState, useEffect} from 'react';

import CUNote from '../CreateUpdate/CUNote';
import { updateNote } from '../../http/notesAPI';
import { INote } from '../../types/types';

interface ModalNoteUpdateProps {
    show: boolean;
    onHide: () => void;
    note: INote;
};


const ModalNoteUpdate: React.FC<ModalNoteUpdateProps> = ({show, onHide, note}) => {
    const [text, setText] = useState<string>('');

    useEffect(() => {
        setText(note.text);
    }, [show]);

    return (
        <CUNote
            id={note.id}
            text={text}
            setText={setText}
            handler={updateNote}
            modalTitle='Обновить запись'
            btnTitle='Обновить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalNoteUpdate;