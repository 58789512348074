import React, {useState, useContext, useEffect} from 'react';

import { updateArticleCommon } from '../../http/articlesCommonAPI';
import { updateArticlePrivate } from '../../http/articlesPrivateAPI';
import { updateAudioCommon } from '../../http/audiosCommonAPI';
import { updateAudioPrivate } from '../../http/audiosPrivateAPI';
import { updateVideoCommon } from '../../http/videosCommonAPI';
import { updateVideoPrivate } from '../../http/videosPrivateAPI';
import { Context } from '../..';
import { ILink } from '../../types/types';
import CULink from '../CreateUpdate/CULink';

interface ModalLinkUpdateProps {
    show: boolean;
    onHide: () => void;
    pageTitle: string;
    linkItem: ILink;
};

const ModalLinkUpdate: React.FC<ModalLinkUpdateProps> = ({show, onHide, pageTitle, linkItem}) => {
    const {users} = useContext(Context);
    const [title, setTitle] = useState<string>('');    
    const [link, setLink] = useState<string>('');
    let handler;

    useEffect(() => {
        setTitle(linkItem.title);
        setLink(linkItem.link);
    }, [show]);

    switch (pageTitle) {
        case 'Аудио' :
            handler = users.role === "ADMIN" ? updateAudioCommon : updateAudioPrivate;
            break;
        case 'Видео' :
            handler = users.role === "ADMIN" ? updateVideoCommon : updateVideoPrivate;
            break;
        case 'статей' :
            handler = users.role === "ADMIN" ? updateArticleCommon : updateArticlePrivate;
            break;
    }

    return (
        <CULink
            id={linkItem.id}
            title={title}
            link={link}
            setTitle={setTitle}
            setLink={setLink}
            // @ts-ignore
            handler={handler}
            modalTitle='Обновить ссылку'
            btnTitle='Обновить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalLinkUpdate;