import React, {useState, useContext} from 'react';
import {Container, Button} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';

// import ModalAddProject from '../../components/Modals/ModalAddProject';
// import ModalAddExp from '../../components/Modals/ModalAddExp';
// import ModalEditContacts from '../../components/Modals/ModalEditContacts';
// import ModalAddSkill from '../../components/Modals/ModalAddSkill';
// import ModalEditAbout from '../../components/Modals/ModalEditAbout';
// import ModalAddAboutDescr from '../../components/Modals/ModalAddAboutDescr';
// import {Context} from '../../';
import {MAIN_ROUTE} from '../../utils/consts';

import './adminPage.sass';


const AdminPage: React.FC = () => {
    const [projectVisible, setProjectVisible] = useState<boolean>(false);
    const [skillVisible, setSkillVisible] = useState<boolean>(false);
    const [expVisible, setExpVisible] = useState<boolean>(false);
    const [aboutVisible, setAboutVisible] = useState<boolean>(false);
    const [aboutDescrVisible, setAboutDescrVisible] = useState<boolean>(false);
    const [contactsVisible, setContactsVisible] = useState<boolean>(false);
    const [exp, setExp] = useState<boolean>(false);

    // const {admin} =useContext(Context);
    const navigate = useNavigate();

    const eduHandler = () => {
        setExpVisible(true);
        setExp(false);
    };

    const expHandler = () => {
        setExpVisible(true);
        setExp(true);
    };

    return (
        <Container className="admin">
            <Helmet>
                <title>Админ. панель</title>
                <meta name="description" content="Портфолио | Админ. панель" />
            </Helmet>

            {/* <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => setProjectVisible(true)}
                >
                Добавить Запись в Мотивацию - НЕТ
            </Button>
            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={() => setSkillVisible(true)}
                >
                Добавить Запись в Методику - НЕТ
            </Button> */}
            <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={eduHandler}
                >
                Добавить Рекламный блок
            </Button>
            {/* <Button 
                variant={"outline-secondary"} 
                className="mt-4 p-2 shadow"
                onClick={expHandler}
                >
                Изменить Методику - НЕТ
            </Button> */}
            
            {/* <ModalAddProject show={projectVisible} onHide={() => setProjectVisible(false)} />        
            <ModalAddSkill show={skillVisible} onHide={() => setSkillVisible(false)} />
            <ModalAddExp show={expVisible} onHide={() => setExpVisible(false)} exp={exp} />
            <ModalEditAbout show={aboutVisible} onHide={() => setAboutVisible(false)} />
            <ModalAddAboutDescr show={aboutDescrVisible} onHide={() => setAboutDescrVisible(false)} />
            <ModalEditContacts show={contactsVisible} onHide={() => setContactsVisible(false)} /> */}
        </Container>
    );
};

export default AdminPage;