import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Button, Image} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { ARTICLES_ROUTE, AUDIO_ROUTE, IMAGES_ROUTE, NOTES_ROUTE, QUOTES_ROUTE, VIDEO_ROUTE } from "../../utils/consts";

import './materialsPage.sass';

const picture = require('../../assets/img_02.jpg');

const MaterialsPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container className='materials'>
            <Helmet>
                <title>Материалы</title>
                <meta name="description" content="Материалы" />
            </Helmet>           

            <h2 className='materials__title'>Выбери что тебе интересно</h2>
            <div className='materials__wrapper'>
                <div className='materials__btns'>
                    <Button 
                        variant={"outline-secondary"} 
                        className="mt-4 p-2 shadow"
                        onClick={() => navigate(AUDIO_ROUTE)}
                        >
                        Аудио
                    </Button>                   
                    <Button 
                        variant={"outline-secondary"} 
                        className="mt-4 p-2 shadow"
                        onClick={() => navigate(VIDEO_ROUTE)}
                        >
                        Видео
                    </Button>
                    <Button 
                        variant={"outline-secondary"} 
                        className="mt-4 p-2 shadow"
                        onClick={() => navigate(IMAGES_ROUTE)}
                        >
                        Картинки
                    </Button>
                    <Button 
                        variant={"outline-secondary"} 
                        className="mt-4 p-2 shadow"
                        onClick={() => navigate(QUOTES_ROUTE)}
                        >
                        Цитаты
                    </Button>
                    <Button 
                        variant={"outline-secondary"} 
                        className="mt-4 p-2 shadow"
                        onClick={() => navigate(ARTICLES_ROUTE)}
                        >
                        Статьи
                    </Button>
                    <Button 
                        variant={"outline-secondary"} 
                        className="mt-4 p-2 shadow"
                        onClick={() => navigate(NOTES_ROUTE)}
                        >
                        Записи
                    </Button>
                </div>
                <Image src={picture} className='materials__img' />
            </div>
        </Container>
    );
};

export default MaterialsPage;