import React, {useState, useContext} from 'react';

import { createQuoteCommon } from '../../http/quotesCommonAPI';
import { createQuotePrivate } from '../../http/quotesPrivateAPI';
import { Context } from '../..';
import CUQuote from '../CreateUpdate/CUQuote';

interface ModalQuoteAddProps {
    show: boolean;
    onHide: () => void;
};


const ModalQuoteAdd: React.FC<ModalQuoteAddProps> = ({show, onHide}) => {
    const {users} = useContext(Context);
    const [quote, setQuote] = useState<string>('');    
    const [author, setAuthor] = useState<string>('');

    return (
        <CUQuote
            id={0}
            quote={quote}
            author={author}
            setQuote={setQuote}
            setAuthor={setAuthor}
            // @ts-ignore
            handler={users.role === "ADMIN" ? createQuoteCommon : createQuotePrivate}
            modalTitle='Добавить цитату'
            btnTitle='Добавить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalQuoteAdd;