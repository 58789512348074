import React, {useContext} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import { Context } from '../..';

interface CULinkProps {
    id: number;
    title: string;
    link: string;
    setTitle: (title: string) => void;
    setLink: (link: string) => void;
    handler: (id: number, title: string, link: string) => Promise<unknown>;
    modalTitle: string;
    btnTitle: string;
    show: boolean;
    onHide: () => void;
};


const CULink: React.FC<CULinkProps> = observer(({id, title, link, setTitle, setLink, handler, modalTitle, btnTitle, show, onHide}) => {
    const {service} = useContext(Context);

    const onClick = () => {
        if (!title.trim() || !link.trim()) {
            return alert('Все поля обязательны для заполнения');
        } 

        if (btnTitle === 'Добавить') {
            // @ts-ignore
            handler(title, link)
                .then(() => {
                    onHide();
                    setTitle('');
                    setLink('');
                    service.setToggle(!service.toggle);
                })
                .catch(err => alert(err.response.data.message));
        } else if (btnTitle === 'Обновить') {
            handler(id, title, link)
                .then(() => {
                    onHide();
                    setTitle('');
                    setLink('');
                    service.setToggle(!service.toggle);
                })
                .catch(err => alert(err.response.data.message));
        }        
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control
                        className="mt-3"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        placeholder="Введи название ссылки"
                    />
                    <Form.Control
                        className="mt-3"
                        value={link}
                        onChange={e => setLink(e.target.value)}
                        placeholder="Введи ссылку"
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={btnTitle === 'Добавить' ? "outline-success" : "outline-primary"} onClick={onClick}>{btnTitle}</Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CULink;