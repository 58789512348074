import React, {useState, useEffect} from 'react';

import { updateProfile } from '../../http/profilesAPI';
import { IProfileWhy, IProfileHow } from '../../types/types';
import CUProfile from '../CreateUpdate/CUProfile';

interface ModalProfileUpdateProps {
    show: boolean;
    onHide: () => void;
    hows: IProfileHow[];
    whys: IProfileWhy[]
};


const ModalProfileUpdate: React.FC<ModalProfileUpdateProps> = ({show, onHide, hows, whys}) => {
    const [howz, setHows] = useState<IProfileHow[]>(hows);
    const [whyz, setWhys] = useState<IProfileWhy[]>(whys);

    useEffect(() => {
        setHows(hows);
        setWhys(whys);
    }, [show]);
        
    return (
        <CUProfile
            hows={howz}
            whys={whyz}
            setHows={setHows}
            setWhys={setWhys}
            handler={updateProfile}
            modalTitle='Обновить анкету'
            btnName='Обновить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalProfileUpdate;