import React, {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { IImage } from '../../types/types';

interface CarouselProps {
    images: IImage[];
    id: number;
};


const CarouselFade: React.FC<CarouselProps> = ({images, id}) => {
    const [index, setIndex] = useState<number>(id);

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        {images.map(image =>
            <Carousel.Item>
                <img
                    className="images__item_large"
                    // className="d-block w-100"
                    src={process.env.REACT_APP_API_URL + image.image} 
                    alt={"image" + image.id}
                    key={image.id}
                />
            </Carousel.Item>
        )}
    </Carousel>
  );
}

export default CarouselFade;