import { $authHost } from ".";

export const createAudioCommon = async (title: string, link: number) => {
    const {data} = await $authHost.post('api/audios/common', {title, link});
    return data;
};

export const fetchAudiosCommon = async () => {
    const {data} = await $authHost.get('api/audios/common');
    return data;
};

export const fetchOneAudioCommon = async (id: number) => {
    const {data} = await $authHost.get('api/audios/common/' + id);
    return data;
};

export const updateAudioCommon = async (id: number, title: string, link: number) => {
    const {data} = await $authHost.put('api/audios/common/' + id, {title, link});
    return data;
};

export const deleteAudioCommon = async (id: number) => {
    const {data} = await $authHost.delete('api/audios/common/' + id);
    return data;
};