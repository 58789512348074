import React, {useState, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {Container, Button, Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite'

import List from '../../components/List/List';
import ListItemQuote from '../../components/ListItemQuote/ListItemQuote';
import ModalQuoteAdd from '../../components/Modals/ModalQuoteAdd';
import ModalQuoteUpdate from '../../components/Modals/ModalQuoteUpdate';
import { IQuote } from '../../types/types';
import { Context } from '../..';
import { fetchQuotesCommon, deleteQuoteCommon } from '../../http/quotesCommonAPI';
import { fetchQuotesPrivate, deleteQuotePrivate } from '../../http/quotesPrivateAPI';
import { MATERIALS_ROUTE } from '../../utils/consts';

import './quotesPage.sass';


const QuotesPage: React.FC = observer(() => {
    const navigate = useNavigate();
    const {users, service} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
    const [quote, setQuote] = useState<IQuote>({} as IQuote);
    const [quotes, setQuotes] = useState<IQuote[]>([]);
    const [userQuotes, setUserQuotes] = useState<IQuote[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchQuotesPrivate().then(data => setUserQuotes(data));
        fetchQuotesCommon()
            .then(data => {
                setQuotes(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, [service.toggle]);

    // const quotes: IQuote[] = [
    //     {
    //         id: 1,
    //         quote: 'Терпи и воздерживайся. НЕ удовлетворением своих праздных желаний достигается свобода, а избавлением себя от таких желаний.',
    //         author: 'Эпиктет',
    //         userId: 1
    //     },
    //     {
    //         id: 2,
    //         quote: 'Героин умеет ждать…',
    //         author: '',
    //         userId: 1
    //     },
    //     {
    //         id: 3,
    //         quote: 'Избегай удовольствий, и они сами придут к тебе.',
    //         author: 'Чжуан-Цзы',
    //         userId: 1
    //     },
    //     {
    //         id: 4,
    //         quote: 'Нет путей неправильных. Есть мой выбор и его последствия.',
    //         author: 'Виктор Третьяков',
    //         userId: 1
    //     },
    //     {
    //         id: 5,
    //         quote: 'Мелочи не играют решающей роли - они решают всё!',
    //         author: 'Харви Маккей',
    //         userId: 1
    //     },
    //     {
    //         id: 6,
    //         quote: 'Победа не даёт силу. Силу даёт борьба. Если ты борешься и не сдаёшься - это и есть сила.',
    //         author: '',
    //         userId: 1
    //     },
    // ];

    const removeItem = (quote: IQuote) => {
        if (window.confirm('Ты действительно хочешь удалить эту цитату?')) {
            if (users.role === "ADMIN") {
                deleteQuoteCommon(quote.id);
            } else {
                deleteQuotePrivate(quote.id);
            }
            service.setToggle(!service.toggle);
        }
    };
    const editItem = (quote: IQuote) => {
        setQuote(quote);
        setVisibleUpdate(true);
    };

    if (loading) {
        return <Spinner />
    }

    return (
        <Container className='quotes'>
            <Helmet>
                <title>Цитаты</title>
                <meta name="description" content="Материалы" />
            </Helmet>

            <h2 className='quotes__title'>Список цитат:</h2>
            <List 
                items={quotes}
                renderItem={(item: IQuote) => 
                    <ListItemQuote 
                        onDelete={() => removeItem(item)} 
                        onEdit={() => editItem(item)} 
                        item={item} 
                        key={item.id}
                        role={users.role}
                    />
                } 
            />

            <Button variant={"outline-success"} className='quotes__btn' onClick={() => setVisible(true)} >Добавить</Button>
            <Button variant={"outline-secondary"} className='quotes__btn quotes__btn_back' onClick={() => navigate(MATERIALS_ROUTE)} >Назад</Button>

            {users.role !== "ADMIN" && userQuotes.length ? 
                <div>
                    <h3 className='quotes__title'>Добавленные:</h3>
                    <List 
                        items={userQuotes}
                        renderItem={(item: IQuote) => 
                            <ListItemQuote 
                                onDelete={() => removeItem(item)} 
                                onEdit={() => editItem(item)} 
                                item={item} 
                                key={item.id}
                                role="ADMIN"
                            />
                        } 
                    />
                </div>  
            : <div></div>}
            <ModalQuoteAdd show={visible} onHide={() => setVisible(false)} />
            <ModalQuoteUpdate show={visibleUpdate} onHide={() => setVisibleUpdate(false)} quoteItem={quote} />
        </Container>
    );
});

export default QuotesPage;