import React, {useState, useContext, useEffect} from 'react';
import {Container, Button, Spinner} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {observer} from 'mobx-react-lite';

import ModalProfileAdd from '../../components/Modals/ModalProfileAdd';
import ModalProfileUpdate from '../../components/Modals/ModalProfileUpdate';
import { IProfile } from '../../types/types';
import { Context } from '../..';
import { fetchProfile } from '../../http/profilesAPI';

import './profilePage.sass';


const ProfilePage: React.FC = observer(() => {
    const {users, service} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [profile, setProfile] = useState<IProfile>({} as IProfile);

    useEffect(() => {
        fetchProfile()
            .then(data => {
                setProfile(data);
                setLoading(false);
            })
            .catch(err => console.log(err));
    }, [service.toggle]);

    // const profile: IProfile = {
    //     id: 1,
    //     whys: [
    //         {
    //             id: 1,
    //             why: "Быть Мужчиной",
    //             profileId: 1
    //         },
    //         {
    //             id: 2,
    //             why: "Иметь секс в реальности, а не в фантазиях",
    //             profileId: 1
    //         },
    //         {
    //             id: 3,
    //             why: "Решить проблему с отношениями",
    //             profileId: 1
    //         },
    //         {
    //             id: 4,
    //             why: "Иметь прочный фундамент самооценки",
    //             profileId: 1
    //         }
    //     ],
    //     hows: [
    //         {
    //             id: 1,
    //             how: "Стабилизируется самооценка, возрастёт уверенность в себе",
    //             profileId: 1
    //         },
    //         {
    //             id: 2,
    //             how: "Время, которое раньше уходило на восстановление, будет высвобождено и направлено на достижение целей",
    //             profileId: 1
    //         }
    //     ],
    //     userId: 1
    // };

    if (users.role === 'ADMIN') {
        return <div></div>
    }


    return (
        <Container className='profile'>
            <Helmet>
                <title>Анкета</title>
                <meta name="Profile" content="Анкета" />
            </Helmet>

            <h3 className='profile__title'>Анкета</h3>
            {profile.why && profile.why.length ?
                <Button 
                    variant={"outline-secondary"} 
                    className="mt-4 p-2 shadow"
                    onClick={() => setVisibleUpdate(true)}
                    >
                    Обновить анкету
                </Button>
                :
                <Button 
                    variant={"outline-secondary"} 
                    className="mt-4 p-2 shadow"
                    onClick={() => setVisible(true)}
                    >
                    Заполнить анкету
                </Button>
            }
            
            {/* <div className='profile__title'>Ответы на основополагающие вопросы</div>     */}
            <div>
                <div className='profile__question'>
                    Зачем мне это?<br/>
                    Чего я хочу достигнуть, решив эту проблему?
                </div>
                
                {loading ?
                    <Spinner />
                    :
                    <div>
                        Я хочу победить онанизм и жить без онанизма и порнографии стабильно для того, чтобы:
                        <ul className='profile__list'>
                            {profile.why && profile.why.map(item =>
                                <li key={item.id}>{item.why}</li>
                            )}
                        </ul>
                    </div>
                }
            </div>
            <div>
                <div className='profile__question'>Как победа над ОП поможет достигнуть моей цели?</div>
                {loading ?
                    <Spinner />
                    :
                    <ul className='profile__list'>
                        {profile.how && profile.how.map(item =>
                            <li key={item.id}>{item.how}</li>
                        )}
                    </ul>
                }
            </div>

            <ModalProfileAdd show={visible} onHide={() => setVisible(false)} />
            <ModalProfileUpdate show={visibleUpdate} onHide={() => setVisibleUpdate(false)} hows={profile.how} whys={profile.why} />
        </Container>
    );
});

export default ProfilePage;