export const enum Categories {
    ACTIVITY = 'Деятельность',
    LIFESTYLE = 'Образ жизни',
    SITUATION = 'Ситуация',
    CONDITION = 'Состояние',
    THINKING = 'Мысли'
};

export interface IElm {
    id: number;
    title: string;
    userId: number;
};

export interface IImage {
    id: number;
    image: string;
    userId: number;
};

export interface IItem {
    id: number;
    name: string;
    category: string;
    userId: number;
};

export interface ILink {
    id: number;
    title: string;
    link: string;
    userId: number;
};

export interface INote {
    id: number;
    createdAt: string;
    text: string;
    userId: number;
};

export interface IProfileWhy {
    id: number;
    why: string;
    profileId: number;
};

export interface IProfileHow {
    id: number;
    how: string;
    profileId: number;
};

export interface IProfile {
    id: number;
    why: IProfileWhy[];
    how: IProfileHow[];
    userId: number;
};

export interface IQuote {
    id: number;
    quote: string;
    author: string;
    userId: number;
};