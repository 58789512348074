import React from 'react';
import {Container} from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import './edgePage.sass';


const EdgePage: React.FC = () => {
    return (
        <Container className='edge'>
            <Helmet>
                <title>На гране срыва</title>
                <meta name="description" content="На гране срыва" />
            </Helmet> 
            
            <div>
                <ol className="edge__list">Алгоритм такой:
                    <li>Прерви деятельность, способствующую ОП</li>
                    <li>Сломай ситуацию</li>
                    <li>Займись деятельностью, способствующей ЖБОиП</li>
                </ol>
                Более подробно:
                <ul className="method__list">
                <li>Ситуация
                    <ul>
                        <li>Устрани причину</li>
                        <li>Сломай ситуацию</li>
                    </ul>
                </li>
                <li>Деятельность
                    <ul>
                        <li>Вовлечённая / умственная</li>
                        <li>Полностью поглощающая / физическая</li>
                        <li>Важная / срочная</li>
                    </ul>
                </li>
                <li>Мысли
                    <ul>
                        <li>Разотождествление</li>
                        <li>Вернись в реальность</li>
                        <li>Это временно, это пройдёт</li>
                    </ul>
                </li>
                <li>Убеждения
                    <ul>
                        <li>Я выстою!</li>
                        <li>Отношение к женскому телу</li>
                        <li>Хочешь секса в реальности - откажись в фантазиях</li>
                        <li>Отношение к семени</li>
                    </ul>
                </li>
                <li>Мотивация
                    <ul>
                        <li>Мотивационный контент: видео, аудио, книги, статьи, форумы</li>
                        <li>Твоя Анкета, чего ты хочешь достичь</li>
                    </ul>
                </li>
                <li>НКД</li>
            </ul>
            </div>
        </Container>
    );
};

export default EdgePage;