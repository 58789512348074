import { $authHost } from ".";

export const createNote = async (text: string) => {
    const {data} = await $authHost.post('api/notes', {text});
    return data;
};

export const fetchNotes = async () => {
    const {data} = await $authHost.get('api/notes');
    return data;
};

export const fetchOneNote = async (id: number) => {
    const {data} = await $authHost.get('api/notes/' + id);
    return data;
};

export const updateNote = async (id: number, text: string) => {
    const {data} = await $authHost.put('api/notes/' + id, {text});
    return data;
};

export const deleteNote = async (id: number) => {
    const {data} = await $authHost.delete('api/notes/' + id);
    return data;
};