import React, {useContext} from 'react';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import { IProfileWhy, IProfileHow } from '../../types/types';
import { Context } from '../..';

interface CUProfileProps {
    hows: IProfileHow[];
    whys: IProfileWhy[];
    setHows: (hows: IProfileHow[]) => void;
    setWhys: (whys: IProfileWhy[]) => void;
    handler: (profile: FormData) => Promise<unknown>;
    modalTitle: string;
    btnName: string;
    show: boolean;
    onHide: () => void;
};


const CUProfile: React.FC<CUProfileProps> = observer(({hows, whys, setHows, setWhys, handler, modalTitle, btnName, show, onHide}) => {
    const {service} = useContext(Context);

    const addHow = () => {
        // @ts-ignore
        setHows([...hows, {how: '', id: Date.now()}]);
    };

    const removeHow = (id: number) => {
        setHows(hows.filter(item => item.id !== id));
    };

    const changeHow = (key: string, value: string, id: number) => {
        setHows(hows.map(item => item.id === id ? {...item, [key]: value} : item));
    };

    const addWhy = () => {
        // @ts-ignore
        setWhys([...whys, {why: '', id: Date.now()}]);
    };

    const removeWhy = (id: number) => {
        setWhys(whys.filter(item => item.id !== id));
    };

    const changeWhy = (key: string, value: string, id: number) => {
        setWhys(whys.map(item => item.id === id ? {...item, [key]: value} : item));
    };

    const onClick = () => {       
        if (whys.length === 0) {
            return alert('Необходимо дать хотя бы 1 ответ');
        } else if (hows.length === 0) {
            return alert('Необходимо дать хотя бы 1 ответ');
        }
        
        const formData = new FormData();
        formData.append('hows', JSON.stringify(hows));
        formData.append('whys', JSON.stringify(whys));

        handler(formData)
            .then(() => {
                onHide();
                setHows([]);
                setWhys([]);
                service.setToggle(!service.toggle);
            })
            .catch(err => alert(err.response.data.message));
    };


    return (
        <Modal
            show={show}
            onHide={onHide}
            // @ts-ignore
            size="md"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {modalTitle}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <label htmlFor="why" className="mt-3">Зачем мне это? <br/> Чего я хочу достигнуть, решив эту проблему?</label>
                    <Button 
                        id="why"
                        className="mt-3 w-100"
                        variant="outline-secondary"
                        onClick={addWhy}
                        >Добавить вариант "Зачем мне это?"
                    </Button>
                    {whys && whys.map(item =>
                        <Row className="mt-3" key={item.id}>
                            <Col md={9}>
                                <Form.Control as="textarea"
                                    value={item.why}
                                    onChange={e => changeWhy('why', e.target.value, item.id)}
                                    placeholder="Зачем мне это?"
                                    maxLength={500}
                                />
                            </Col>
                            <Col md={3}>
                                <Button 
                                    variant="outline-danger"
                                    className='mt-1 w-100'
                                    onClick={() => removeWhy(item.id)}
                                    >Удалить
                                </Button>
                            </Col>
                        </Row>    
                    )}

                    <label htmlFor="how" className="mt-3">Как победа над ОП поможет достигнуть моей цели?</label>                    
                    <Button 
                        id="how"
                        className="mt-3 w-100"
                        variant="outline-secondary"
                        onClick={addHow}
                        >Добавить вариант "Как поможет?"
                    </Button>
                    {hows && hows.map(item =>
                        <Row className="mt-3" key={item.id}>
                            <Col md={9}>
                                <Form.Control as="textarea"
                                    value={item.how}
                                    onChange={e => changeHow('how', e.target.value, item.id)}
                                    placeholder="Как поможет?"
                                    maxLength={500}
                                />
                            </Col>
                            <Col md={3}>
                                <Button 
                                    variant="outline-danger"
                                    className='mt-1 w-100'
                                    onClick={() => removeHow(item.id)}
                                    >Удалить
                                </Button>
                            </Col>
                        </Row>    
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant={btnName === 'Добавить' ? "outline-success" : btnName === 'Обновить' ? "outline-primary" :  "outline-info"} 
                    onClick={onClick}>
                        {btnName}
                </Button>
                <Button variant={"outline-secondary"} onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CUProfile;