import React, { useContext, useState, useEffect } from "react";
import {BrowserRouter} from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { Spinner } from "react-bootstrap";

import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar/NavBar";
import AsideNav from "./components/AsideNav/AsideNav";
import { Context } from "./index";
import { check } from "./http/userAPI";


const App = observer(() => {
    const {users} = useContext(Context);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        check()
            .then(data => {
                // @ts-ignore
                users.setRole(data.role);
                users.setIsAuth(true);
            })
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <Spinner animation={"border"}/>
    }

    return (
        <BrowserRouter>
            <NavBar />
            <AsideNav />
            <AppRouter />
        </BrowserRouter>
    );
});

export default App;