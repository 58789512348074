import React, {useState, useEffect, useContext} from 'react';
import {Container, Spinner} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import ItemsComponent from '../components/ItemsComponent/ItemsComponent';
import { IItem } from '../types/types';
import { Context } from '..';
import { fetchZbos } from '../http/zboAPI';
import { deleteZbo } from '../http/zboAPI';


const ZboPage: React.FC = observer(() => {
    const {users, service} = useContext(Context);
    const [items, setItems] = useState<IItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchZbos()
            .then(data => {
                setItems(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, [service.toggle]);

    // const items: IItem[] = [
    //     {
    //         id: 1,
    //         name: 'Звонок другу',
    //         category: 'Деятельность',
    //         userId: 1
    //     },
    //     {
    //         id: 2,
    //         name: 'Уборка',
    //         category: 'Деятельность',
    //         userId: 1
    //     },
    //     {
    //         id: 3,
    //         name: 'Вне дома',
    //         category: 'Ситуация',
    //         userId: 1
    //     },
    // ];

    if (users.role === 'ADMIN') {
        return <div></div>
    }

    if (loading) {
        return <Spinner />
    }


    return (
        <Container className='zbo'>       
            <ItemsComponent
                title="Способствует ЖБОиП"
                advice="Делай чаще"
                items={items}
                handler={deleteZbo}
            />
        </Container>
    );
});

export default ZboPage;