import React, {useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {Container, Button} from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import { deleteArticleCommon } from '../../http/articlesCommonAPI';
import { deleteArticlePrivate } from '../../http/articlesPrivateAPI';
import { deleteAudioCommon } from '../../http/audiosCommonAPI';
import { deleteAudioPrivate } from '../../http/audiosPrivateAPI';
import { deleteVideoCommon } from '../../http/videosCommonAPI';
import { deleteVideoPrivate } from '../../http/videosPrivateAPI';
import { ILink } from '../../types/types';
import { Context } from '../..';
import { MATERIALS_ROUTE } from '../../utils/consts';
import ModalLinkAdd from '../Modals/ModalLinkAdd';
import ModalLinkUpdate from '../Modals/ModalLinkUpdate';

import './linksComponent.sass';

interface LinksComponentProps {
    title: string;
    links: ILink[];
    userLinks: ILink[];
};


const LinksComponent: React.FC<LinksComponentProps> = observer(({title, links, userLinks}) => {
    const {users, service} = useContext(Context);
    const [visible, setVisible] = useState<boolean>(false);
    const [visibleUpdate, setVisibleUpdate] = useState<boolean>(false);
    const [link, setLink] = useState<ILink>({} as ILink);
    const navigate = useNavigate();
    let handler = (id: number): void => {};

    switch (title) {
        case 'Аудио' :
            handler = users.role === "ADMIN" ? deleteAudioCommon : deleteAudioPrivate;
            break;
        case 'Видео' :
            handler = users.role === "ADMIN" ? deleteVideoCommon : deleteVideoPrivate;
            break;
        case 'статей' :
            handler = users.role === "ADMIN" ? deleteArticleCommon : deleteArticlePrivate;
            break;
    }

    const onDelete = (link: ILink) => {
        if (window.confirm('Ты действительно хочешь удалить этот пункт?')) {
            handler(link.id);
            service.setToggle(!service.toggle);
        }
    };
    const onEdit = (link: ILink) => {
        setLink(link);
        setVisibleUpdate(true);
    };

    return (
        <Container className='links'>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content="Материалы" />
            </Helmet>

            <h2>Список {title}:</h2>
            <ul className='links__list'>
                {links.length && links.map(link =>
                    <li key={link.id} className='links__list_item'>
                        <a href={link.link} target="_blank">{link.title}</a>
                        {users.role === "ADMIN" &&
                            <div>
                                <i className="bi bi-pencil-fill quote-item__icon" onClick={() => onEdit(link)}></i>
                                <i className="bi bi-trash3-fill quote-item__icon" onClick={() => onDelete(link)}></i>
                            </div>
                        }
                    </li>
                )}
            </ul>

            <Button variant={"outline-success"} className='links__btn' onClick={() => setVisible(true)} >Добавить</Button>
            <Button variant={"outline-secondary"} className='links__btn links__btn_back' onClick={() => navigate(MATERIALS_ROUTE)} >Назад</Button>

            {users.role !== "ADMIN" && userLinks.length ? 
                <div>
                    <h3>Добавленные:</h3>
                    <ul className='links__list'>
                        {userLinks.map(link =>
                            <li key={link.id} className='links__list_item'>
                                <a href={link.link} target="_blank">{link.title}</a>
                                <div>
                                    <i className="bi bi-pencil-fill quote-item__icon" onClick={() => onEdit(link)}></i>
                                    <i className="bi bi-trash3-fill quote-item__icon" onClick={() => onDelete(link)}></i>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>  
            : <div></div>}

            <ModalLinkAdd show={visible} onHide={() => setVisible(false)} pageTitle={title} />
            <ModalLinkUpdate show={visibleUpdate} onHide={() => setVisibleUpdate(false)} pageTitle={title} linkItem={link} />
        </Container>
    );
});

export default LinksComponent;