import React, {useState} from 'react';

import { createProfile } from '../../http/profilesAPI';
import { IProfileWhy, IProfileHow } from '../../types/types';
import CUProfile from '../CreateUpdate/CUProfile';

interface ModalProfileAddProps {
    show: boolean;
    onHide: () => void;
};


const ModalProfileAdd: React.FC<ModalProfileAddProps> = ({show, onHide}) => {
    const [hows, setHows] = useState<IProfileHow[]>([]);
    const [whys, setWhys] = useState<IProfileWhy[]>([]);

    return (
        <CUProfile
            hows={hows}
            whys={whys}
            setHows={setHows}
            setWhys={setWhys}
            handler={createProfile}
            modalTitle='Заполнить анкету'
            btnName='Добавить'
            show={show}
            onHide={onHide}
        />
    );
};

export default ModalProfileAdd;