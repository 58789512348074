import React, {useState} from 'react';
import { Card } from 'react-bootstrap';

import { INote } from '../../types/types';

import './listItemQuote.sass';

interface ListItemNoteProps {
    item: INote;
    onDelete: (item: INote) => void;
    onEdit: (item: INote) => void;
};


const ListItemNote: React.FC<ListItemNoteProps> = ({item, onDelete, onEdit}) => {
    const [hover, setHover] = useState<boolean>(false);

    return (
        <Card 
            className="quote-item"
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div>
                <div className="quote-item__author">{item.createdAt.substring(0, 10)}</div>
                <div className="quote-item__quote">{item.text}</div>
            </div>
            <div
                style={{display: hover ? 'block' : 'none'}}
                // style={{visibility: hover ? 'visible' : 'hidden'}}
            >
                <i className="bi bi-pencil-fill quote-item__icon" onClick={() => onEdit(item)}></i>
                <i className="bi bi-trash3-fill quote-item__icon" onClick={() => onDelete(item)}></i>
            </div>
        </Card>      
    );
};

export default ListItemNote;