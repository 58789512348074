import React from 'react';
import {useParams} from 'react-router-dom';

import MethodBlock0 from '../../components/MethodBlock/MethodBlock0';
import MethodBlock1 from '../../components/MethodBlock/MethodBlock1';
import MethodBlock2 from '../../components/MethodBlock/MethodBlock2';
import MethodBlock3 from '../../components/MethodBlock/MethodBlock3';
import MethodBlock4 from '../../components/MethodBlock/MethodBlock4';
import MethodBlock5 from '../../components/MethodBlock/MethodBlock5';
import MethodBlock6 from '../../components/MethodBlock/MethodBlock6';
import Page404 from '../Page404';


const MethodPage: React.FC = () => {
    const {id} = useParams<{id: string}>();

    switch (String(Number(id) - 1)) {
        case '0':
            return <MethodBlock0 />
        case '1':
            return <MethodBlock1 />
        case '2':
            return <MethodBlock2 />
        case '3':
            return <MethodBlock3 />
        case '4':
            return <MethodBlock4 />
        case '5':
            return <MethodBlock5 />
        case '6':
            return <MethodBlock6 />
        default:
            return <Page404 />
    }
};

export default MethodPage;