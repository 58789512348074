import React, {useState, useEffect, useContext} from 'react';
import { Spinner } from 'react-bootstrap';
import {observer} from 'mobx-react-lite';

import LinksComponent from '../components/LinksComponent/LinksComponent';
import { ILink } from '../types/types';
import { fetchAudiosCommon } from '../http/audiosCommonAPI';
import { fetchAudiosPrivate } from '../http/audiosPrivateAPI';
import { Context } from '..';


const AudioPage: React.FC = observer(() => {
    const {service} = useContext(Context);
    const [links, setLinks] = useState<ILink[]>([]);
    const [userLinks, setUserLinks] = useState<ILink[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchAudiosPrivate().then(data => setUserLinks(data));
        fetchAudiosCommon()
            .then(data => {
                setLinks(data);
                setLoading(false);
            })
            .catch(err => {
                alert('Произошла ошибка при загрузке данных с удалённого сервера.');
                console.log(JSON.parse(err.request.response).message);
            });
    }, [service.toggle]);

    if (loading) {
        return <Spinner />
    }

    return (
        <div>
            <LinksComponent
                title='Аудио'
                links={links}
                userLinks={userLinks}
            />
        </div>
    );
});

export default AudioPage;