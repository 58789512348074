import React, {useContext} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {Navbar, Container, Button, Nav} from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import {Context} from '../../index';
import { MAIN_ROUTE, MANUAL_ROUTE, METHODOLOGY_ROUTE, PROFILE_ROUTE, FEEDBACK_ROUTE, ADMIN_ROUTE } from "../../utils/consts";

import './navBar.sass';


const NavBar: React.FC = observer(() => {
    const {users} = useContext(Context);
    const navigate = useNavigate();

    const logOut = () => {
        users.setIsAuth(false);
        users.setRole('');
        localStorage.clear();
    };


    return (
        <>
            {users.isAuth ?
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top" >
                    <Container >
                        <NavLink className="active" to={MAIN_ROUTE}>BEZONAN</NavLink>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-around'>
                            <Nav className="ms-5 me-auto">
                                <Nav.Link href="#" id='btn-manual' onClick={() => navigate(MANUAL_ROUTE)}>Инструкция</Nav.Link>
                                <Nav.Link href="#1" onClick={() => navigate(METHODOLOGY_ROUTE)}>Методика</Nav.Link>
                                <Nav.Link href="#2" onClick={() => navigate(PROFILE_ROUTE)}>Анкета</Nav.Link>
                                <Nav.Link href="#3" onClick={() => navigate(FEEDBACK_ROUTE)}>Обратная связь</Nav.Link>
                            </Nav> 
                            {users.role === 'ADMIN' ?
                                <Button 
                                    variant={"outline-light"} 
                                    className="me-2 nav-btn"
                                    onClick={() => navigate(ADMIN_ROUTE)}
                                    >Админ.панель
                                </Button>
                                :
                                <div></div>
                            } 
                            <Button 
                                variant={"outline-light"} 
                                onClick={() => logOut()} 
                                className="ms-2 nav-btn"
                                >Выйти
                            </Button>  
                        </Navbar.Collapse>       
                    </Container>
                </Navbar>
            :
            <div></div>
            }
        </>       
    );
});

export default NavBar;